const VIEWS = {
  COMMON: 'common',
  WITHOUT_BORDER: 'without-border'
}

const OVERLAYS = {
  COMMON: 'common',
  TRANSPARENT: 'transparent'
}

const ANIMATIONS = {
  COMMON: 'common',
  NONE: 'none'
}

export {
  VIEWS,
  OVERLAYS,
  ANIMATIONS
}
