import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { tagNodes } from '@/api'
import { useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const loadingSearch = ref(false)
  const processing = ref(false)

  async function getNodeCatalog() {
    const data = {}
    loading.value = true
    try {
      const response = await tagNodes.getNodes($route.params.projectId)
      data.response = response.catalog
    } catch (e) {
      data.error = e
      console.error(e)
      notificationsStore.showNotification('error', $t('api.tagNodes.http.getNodes.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function searchNodeCatalog(params, pagination) {
    const data = {}
    loadingSearch.value = true
    try {
      const response = await tagNodes.searchNodes({
        projectId: $route.params.projectId,
        q: params.q,
        type: params.type,
        sort: params.sort,
        sortDirection: params.sortDirection,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response.search
      data.total = response.total
    } catch (e) {
      data.error = e
      console.error('search error', e)
      notificationsStore.showNotification('error', $t('api.tagNodes.http.searchNodes.error'))
    } finally {
      loadingSearch.value = false
    }

    return data
  }

  async function addNodeCatalog(parentNode, name) {
    const data = {}
    processing.value = true
    try {
      const response = await tagNodes.addNode({
        projectId: $route.params.projectId,
        name,
        parentNodeId: parentNode
      })
      data.response = response.catalog
    } catch (e) {
      data.error = e
      console.error('add node error', e)
      notificationsStore.showNotification('error', $t('api.tagNodes.http.addNode.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function editNodeCatalog(node, name) {
    const data = {}
    processing.value = true
    try {
      const response = await tagNodes.editNode({
        componentNodeId: node,
        name
      })
      data.response = response.catalog
    } catch (e) {
      data.error = e
      console.error('edit node error', e)
      notificationsStore.showNotification('error', $t('api.tagNodes.http.editNode.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteNodeCatalog(node, safeChilds) {
    const data = {}
    processing.value = true
    try {
      const response = await tagNodes.deleteNode({
        componentNodeId: node,
        safeChilds
      })
      data.response = response.catalog
      notificationsStore.showNotification('success', $t('api.tagNodes.http.deleteNode.success'))
    } catch (e) {
      data.error = e
      console.error('delete node error', e)
      notificationsStore.showNotification('error', $t('api.tagNodes.http.deleteNode.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function moveNodeCatalog({ componentNodeId, moveNodeId, tagIds }) {
    const data = {}
    processing.value = true
    try {
      const response = await tagNodes.moveInNode({
        componentNodeId,
        moveNodeId,
        tagIds
      })
      data.response = response.catalog
    } catch (e) {
      data.error = e
      console.error('add node error', e)
      notificationsStore.showNotification('error', $t('api.tagNodes.http.moveInNode.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    loadingSearch,
    processing,
    getNodeCatalog,
    searchNodeCatalog,
    addNodeCatalog,
    editNodeCatalog,
    deleteNodeCatalog,
    moveNodeCatalog
  }
}
