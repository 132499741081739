import http from '../instance'

const getProjectAccess = projectId =>
  http.get(`/access/projects/${projectId}`)

const getAccessGroups = organizationId =>
  http.get('/access/groups', { params: { organizationId } })

export default {
  getProjectAccess,
  getAccessGroups
}
