import http from '../instance'

const getTags = ({
  projectId,
  q,
  selected,
  page,
  size
}) => {
  const params = { projectId }

  if (q) {
    params.q = q
  }

  if (selected) {
    params.selected = selected
  }

  if ((page || page === 0) && size) {
    params.page = page
    params.size = size
  }

  return http.get('/tags', { params })
}

const getTagsByNode = ({
  componentNodeId,
  type,
  event,
  state,
  sort,
  sortDirection,
  verbose,
  page,
  size
}) => {
  const params = { componentNodeId, verbose }

  if (type) {
    params.type = type
  }

  if (event) {
    params.event = event
  }

  if (state) {
    params.state = state
  }

  if (sort && sortDirection) {
    params.sort = sort
    params.sortDirection = sortDirection
  }

  if ((page || page === 0) && size) {
    params.page = page
    params.size = size
  }

  return http.get('/tags/by-node', { params })
}

const getTagInfo = tagId => {
  return http.get(`/tags/${tagId}`)
}

const createTags = ({ projectId, tags }) => {
  return http.post('/tags/many', { projectId, tags })
}

const editTag = ({ tagId, form }) => {
  return http.post(`/tags/${tagId}/edit`, form)
}

const editTagValue = ({ tagId, value }) => {
  return http.post(`/tags/${tagId}/value/edit`, {
    value
  })
}

const deleteTags = tagIds => {
  return http.post('/tags/delete/many', { ids: tagIds })
}

export default {
  getTags,
  getTagsByNode,
  getTagInfo,
  createTags,
  editTag,
  editTagValue,
  deleteTags
}
