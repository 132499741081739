import instance from '../instance'
import { composeMsg } from '../instance/utils'

const listenScriptExecution = groupId => transactionId =>
  instance.sendMessage(groupId, composeMsg('LISTEN_SCRIPT', { transactionId }))

const stopListenScriptExecution = groupId => () =>
  instance.sendMessage(groupId, composeMsg('STOP_LISTEN_SCRIPT'))

export default {
  listenScriptExecution,
  stopListenScriptExecution
}
