import http from '../instance'

const getUsers = (organizationId, {
  q,
  groupId,
  status,
  sort,
  sortDirection,
  page,
  size
}) => {
  const params = { organizationId }
  if (q) {
    params.q = q
  }

  if (groupId) {
    params.groupId = groupId
  }

  if (status) {
    params.status = status
  }

  if (sort && sortDirection) {
    params.sort = sort
    params.sortDirection = sortDirection
  }

  if (page && size) {
    params.page = page
    params.size = size
  }

  return http.get('/users', { params })
}

const getUser = userId => http.get(`/users/${userId}`)

const createUser = (organizationId, {
  firstName,
  middleName,
  lastName,
  email,
  notificationsInfo,
  groups
}) => http.post('/users', {
  organizationId,
  firstName,
  middleName,
  lastName,
  email,
  notificationsInfo,
  groups
})

const editUser = (userId, {
  firstName,
  middleName,
  lastName,
  email,
  notificationsInfo,
  groups
}) => http.post(`/users/${userId}/edit`, {
  firstName,
  middleName,
  lastName,
  email,
  notificationsInfo,
  groups
})

const banUser = userId => http.post(`/users/${userId}/ban`)

const unbanUser = userId => http.post(`/users/${userId}/unban`)

const changeUserPassword = userId => http.post(`/users/${userId}/password/reset`)

const deleteUser = userId => http.post(`/users/${userId}/delete`)

export default {
  getUsers,
  getUser,
  createUser,
  editUser,
  banUser,
  unbanUser,
  changeUserPassword,
  deleteUser
}
