import { ref } from 'vue'

import { userGroups } from '@/api'
import { useUser, useNotifications } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const userStore = useUser()
  const notificationsStore = useNotifications()

  const loading = ref()
  const processing = ref()

  async function getUserGroups() {
    const data = {}
    loading.value = true
    try {
      const response = await userGroups.getUserGroups(userStore.getOrganizationId())
      data.response = response.groups
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.getUserGroups.error'))
      console.error('user groups loading error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function getUserGroup(groupId) {
    const data = {}
    loading.value = true
    try {
      const response = await userGroups.getUserGroup(groupId)
      data.response = response.group
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.getUserGroups.error'))
      console.error('user group loading error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function createUserGroup(title) {
    const data = {}
    processing.value = true
    try {
      const response = await userGroups.createUserGroup(userStore.getOrganizationId(), { title })
      data.response = response.group
      notificationsStore.showNotification('success', $t('api.userGroups.http.createUserGroup.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.createUserGroup.error'))
      console.error('user group create error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function editUserGroup(groupId, title) {
    const data = {}
    processing.value = true
    try {
      const response = await userGroups.editUserGroup(groupId, { title })
      data.response = response.group
      notificationsStore.showNotification('success', $t('api.userGroups.http.editUserGroup.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.editUserGroup.error'))
      console.error('user group edit error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function editUserGroupUsers(groupId, users) {
    const data = {}
    processing.value = true
    try {
      const response = await userGroups.editUserGroupUsers(groupId, { users })
      data.response = response.group
      notificationsStore.showNotification('success', $t('api.userGroups.http.editUserGroupUsers.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.editUserGroupUsers.error'))
      console.error('edit user group users error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function editUserGroupProjects(groupId, { projects, projectsAddNew }) {
    const data = {}
    processing.value = true
    try {
      const response = await userGroups.editUserGroupProjects(groupId, { projects, projectsAddNew })
      data.response = response.group
      notificationsStore.showNotification('success', $t('api.userGroups.http.editUserGroupProjects.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.editUserGroupProjects.error'))
      console.error('edit user group projects error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function editUserGroupAccess(groupId, access) {
    const data = {}
    processing.value = true
    try {
      const response = await userGroups.editUserGroupAccess(groupId, { access })
      data.response = response.group
      notificationsStore.showNotification('success', $t('api.userGroups.http.editUserGroupAccess.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.editUserGroupAccess.error'))
      console.error('edit user group access error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteUserGroup(groupId) {
    const data = {}
    processing.value = true
    try {
      await userGroups.deleteUserGroup(groupId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.userGroups.http.deleteUserGroup.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.userGroups.http.deleteUserGroup.error'))
      console.error('delete user group error')
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getUserGroups,
    getUserGroup,
    createUserGroup,
    editUserGroup,
    editUserGroupUsers,
    editUserGroupProjects,
    editUserGroupAccess,
    deleteUserGroup
  }
}
