import { ref } from 'vue'

import { account } from '@/api'
import { useNotifications } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const notificationsStore = useNotifications()

  const loading = ref()
  const processing = ref()

  async function getProfile() {
    const data = {}
    loading.value = true
    try {
      const response = await account.getProfile()
      data.response = response.profile
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.account.http.getProfile.error'))
      console.error(e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function editProfile(organizationId, groups) {
    const data = {}
    processing.value = true
    try {
      const response = await account.editProfile(organizationId, groups)
      data.response = response.profile
      notificationsStore.showNotification('success', $t('api.account.http.editProfile.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.account.http.editProfile.error'))
      console.error(e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function resetPassword(newPassword, token) {
    const data = {}
    processing.value = true
    try {
      const response = await account.resetPassword(newPassword, token)
      data.response = response.session
    } catch (e) {
      data.error = e
      console.error(e)
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getProfile,
    editProfile,
    resetPassword
  }
}
