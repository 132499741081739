import { ref } from 'vue'

import { useNotifications } from '@/stores'
import { notificationSettings } from '@/api'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const loading = ref()
  const processing = ref()

  const notificationsStore = useNotifications()

  async function getTelegramLink() {
    loading.value = true
    const data = {}
    try {
      const response = await notificationSettings.getTelegramLink()
      data.response = response.link
    } catch (e) {
      data.error = e
      console.error('telegram link loading error')
      notificationsStore.showNotification('error', $t('api.notificationSettings.http.getTelegramLink.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function deleteTelegram() {
    const data = {}
    loading.value = true
    try {
      const response = await notificationSettings.deleteTelegram()
      data.response = response.notifications
      notificationsStore.showNotification('success', $t('api.notificationSettings.http.deleteTelegram.success'))
    } catch (e) {
      data.error = e
      console.error('telegram unbinding error')
      notificationsStore.showNotification('error', $t('api.notificationSettings.http.deleteTelegram.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function changeEmail(email, enabled) {
    const data = {}
    processing.value = true
    try {
      const response = await notificationSettings.changeNotificationsEmail(email, enabled)
      data.response = response.notifications
      notificationsStore.showNotification('success', $t('api.notificationSettings.http.changeNotificationsEmail.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notificationSettings.http.changeNotificationsEmail.error'))
      console.error('email notification\'s request error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function changePush(type, enabled) {
    const data = {}
    processing.value = true
    try {
      const response = await notificationSettings.changeNotificationsPush(type, enabled)
      data.response = response.notifications
      notificationsStore.showNotification('success', $t('api.notificationSettings.http.changeNotificationsPush.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notificationSettings.http.changeNotificationsPush.error'))
      console.error('push notification\'s request error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function changeTimezone(timezone) {
    const data = {}
    processing.value = true
    try {
      const response = await notificationSettings.changeNotificationsTimezone(timezone)
      data.response = response.notifications
      notificationsStore.showNotification('success', $t('api.notificationSettings.http.changeNotificationsTimezone.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notificationSettings.http.changeNotificationsTimezone.error'))
      console.error('timezone notification\'s request error')
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getTelegramLink,
    deleteTelegram,
    changeEmail,
    changePush,
    changeTimezone
  }
}
