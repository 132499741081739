import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { useNotifications } from '@/stores'
import { tagTemplates } from '@/api'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref()
  const loadingTags = ref()

  async function getTemplates() {
    const data = {}
    loading.value = true
    try {
      const response = await tagTemplates.getTemplates(route.params.projectId)
      data.response = response.templates
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tagTemplates.http.getTemplates.error'))
      console.error('tag templates loading error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function getTemplateTags(templateId) {
    const data = {}
    loadingTags.value = true
    try {
      const response = await tagTemplates.getTags(templateId)
      data.response = response.tags
    } catch (e) {
      console.error('tag templates loading error', e)
      notificationsStore.showNotification('error', $t('api.tagTemplates.http.getTags.error'))
    } finally {
      loadingTags.value = false
    }

    return data
  }

  async function addTemplate({ name, description, tagIds }) {
    const data = {}
    loading.value = true
    try {
      const response = await tagTemplates.createTagsTemplate({
        projectId: route.params.projectId,
        name,
        description,
        tagIds
      })
      data.response = response.template
      notificationsStore.showNotification('success', $t('api.tagTemplates.http.createTagsTemplate.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tagTemplates.http.createTagsTemplate.error'))
      console.error('error of dashboard adding', e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function deleteTemplate(tagTemplateId) {
    const data = {}
    loading.value = true
    try {
      await tagTemplates.deleteTags(tagTemplateId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.tagTemplates.http.deleteTags.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tagTemplates.http.deleteTags.error'))
      console.error('error of dashboard delete', e)
    } finally {
      loading.value = false
    }

    return data
  }

  return {
    loading,
    loadingTags,
    getTemplates,
    getTemplateTags,
    addTemplate,
    deleteTemplate
  }
}
