import http from '../instance'

const getHistoryTags = signal => ({
  projectId,
  q,
  sort,
  tags,
  type,
  dataTypes,
  event,
  period,
  periodFrom,
  periodTo,
  state,
  page,
  size,
  sortDirection
}) => {
  const params = { projectId }

  if (q) {
    params.q = q
  }

  if (sort) {
    params.sort = sort
  }

  if (sort && sortDirection) {
    params.sortDirection = sortDirection
  }

  if (tags) {
    params.tags = tags
  }

  if (type) {
    params.type = type
  }

  if (dataTypes) {
    params.dataTypes = dataTypes
  }

  if (event) {
    params.event = event
  }

  if (period) {
    params.period = period
  }

  if (periodFrom) {
    params.periodFrom = periodFrom
  }

  if (periodTo) {
    params.periodTo = periodTo
  }

  if (state) {
    params.state = state
  }

  if ((page || page === 0) && size) {
    params.page = page
    params.size = size
  }

  return http.get('/history/tags', { signal, params })
}

const exportHistoryTags = ({
  projectId,
  q,
  sort,
  tags,
  type,
  dataTypes,
  event,
  period,
  periodFrom,
  periodTo,
  state,
  sortDirection
}) => {

  const params = { projectId }

  if (q) {
    params.q = q
  }

  if (sort) {
    params.sort = sort
  }

  if (sort && sortDirection) {
    params.sortDirection = sortDirection
  }

  if (tags) {
    params.tags = tags
  }

  if (type) {
    params.type = type
  }

  if (dataTypes) {
    params.dataTypes = dataTypes
  }

  if (event) {
    params.event = event
  }

  if (period) {
    params.period = period
  }

  if (periodFrom) {
    params.periodFrom = periodFrom
  }

  if (periodTo) {
    params.periodTo = periodTo
  }

  if (state) {
    params.state = state
  }

  return http.post('/history/tags/export', {}, { params })
}


export default {
  getHistoryTags,
  exportHistoryTags
}
