import http from '../instance'

const getNotificationTemplates = signal => ({
  projectId,
  q,
  type,
  severity,
  targets,
  page,
  size
}) => {
  const params = { projectId }

  if (q) {
    params.q = q
  }

  if (type) {
    params.type = type
  }

  if (severity) {
    params.severity = severity
  }

  if (targets) {
    params.targets = targets
  }

  if ((page || page === 0) && size) {
    params.page = page
    params.size = size
  }

  return http.get('/notification-templates', { signal, params })
}

const getNotificationTemplateInfo = notificationTemplateId => {
  return http.get(`/notification-templates/${notificationTemplateId}`)
}

const createNotificationTemplate = ({
  projectId,
  interactionId,
  name,
  severity,
  description,
  notificationTitle,
  notificationText,
  targets,
  channels
}) => {
  const params = {
    projectId,
    interactionId,
    name,
    severity,
    notificationTitle,
    targets,
    channels
  }

  if (description) {
    params.description = description
  }

  if (notificationText) {
    params.notificationText = notificationText
  }

  return http.post('/notification-templates', params)
}

const editNotificationTemplate = (notificationTemplateId, form) => {
  return http.post(`/notification-templates/${notificationTemplateId}/edit`, form)
}

const deleteNotificationTemplate = notificationTemplateId =>
  http.post(`/notification-templates/${notificationTemplateId}/delete`)

export default {
  getNotificationTemplates,
  getNotificationTemplateInfo,
  createNotificationTemplate,
  editNotificationTemplate,
  deleteNotificationTemplate
}
