const getStateMenu = () => {
  return localStorage.getItem('state-side-menu')
}

const updateStateMenu = value => {
  localStorage.setItem('state-side-menu', value)
}

const getTagCatalogWidth = () => {
  const newWidth = localStorage.getItem('tags-catalog-width')
  if (Number.isInteger(parseInt(newWidth))) return newWidth
}

const updateTagCatalogWidth = width => {
  localStorage.setItem('tags-catalog-width', width)
}

export {
  getStateMenu,
  updateStateMenu,
  getTagCatalogWidth,
  updateTagCatalogWidth
}
