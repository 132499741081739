import { nextTick, onBeforeUnmount, ref } from 'vue'

export const PLACEMENTS = {
  LEFT: 'left',
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right'
}

export default function(
  preferredSide,
  indent,
  targetBody,
  openingBody,
  fitTarget = false,
  onScrollEvent = () => {},
  onlyOpposite = false
) {
  const openSettings = ref(null)
  const resizeObs = new ResizeObserver(onResize)
  let resizeTimeoutId

  onBeforeUnmount(() => {
    onScrollEvent()
    onClose()
    clearTimeout(resizeTimeoutId)
    resizeObs.disconnect()
  })

  const minWidth = () => {
    if (fitTarget && targetBody.value) {
      return `width: ${targetBody.value.offsetWidth}px`
    }
    return ''
  }
  const setOpenSettings = (placement, rootSize, bodySize, spaces, viewportSize) => {
    const argNames = ['left', 'right'].includes(placement)
      ? {
        side: 'left',
        secondSide: 'right',
        size: 'width',
        altSize: 'height',
        altSide: 'top',
        altSecondSide: 'bottom'
      }
      : {
        side: 'top',
        secondSide: 'bottom',
        size: 'height',
        altSize: 'width',
        altSide: 'left',
        altSecondSide: 'right'
      }
    const position = placement === argNames.side
      ? rootSize[argNames.side] - bodySize[argNames.size] - indent
      : rootSize[argNames.secondSide] + indent
    const centerPos = rootSize[argNames.altSide] - (bodySize[argNames.altSize] - rootSize[argNames.altSize]) / 2
    const altPosition = centerPos >= 0 && (centerPos + bodySize[argNames.altSize]) < viewportSize[argNames.altSize]
      ? centerPos
      : (Math.min(spaces[argNames.altSide], spaces[argNames.altSecondSide]) === spaces[argNames.altSide]
        ? 0
        : viewportSize[argNames.altSize]-bodySize[argNames.altSize])
    const arrowPosition = rootSize[argNames.altSide] + rootSize[argNames.altSize] / 2

    openSettings.value = {
      placement,
      style: `${argNames.altSide}: ${altPosition}px; ${argNames.side}: ${position}px;${minWidth()}`,
      arrowStyle: arrowPosition < indent || arrowPosition > viewportSize[argNames.altSize] - indent
        ? 'display: none;'
        : `${argNames.altSize}: ${rootSize[argNames.altSize]}px; ${argNames.altSide}: ${rootSize[argNames.altSide] - altPosition}px;`
    }
    window.addEventListener('scroll', onScroll, { capture: true, passive: true })
    window.addEventListener('resize', onClose, { capture: true, passive: true })
    resizeObs.observe(openingBody.value)
  }

  const calcPosition = (afterOpen = () => {}) => {
    const rootSize = targetBody.value.getBoundingClientRect()
    const bodySize = openingBody.value.getBoundingClientRect()
    const viewportSize = {
      width: window?.visualViewport?.width || document?.documentElement?.clientWidth,
      height: window?.visualViewport?.height || document?.documentElement?.clientHeight
    }
    const spaces = {
      top: rootSize.top - indent,
      bottom: viewportSize.height - rootSize.bottom - indent,
      left: rootSize.left - indent,
      right: viewportSize.width - rootSize.right - indent
    }

    const argNames = ['left', 'right'].includes(preferredSide)
      ? {
        size: 'width',
        altSize: 'height'
      }
      : {
        size: 'height',
        altSize: 'width'
      }

    if (bodySize[argNames.altSize] <= viewportSize[argNames.altSize]
      && spaces[preferredSide] >= bodySize[argNames.size]) {
      setOpenSettings(preferredSide, rootSize, bodySize, spaces, viewportSize)
    } else {
      const maxVerticalSide = spaces.top > spaces.bottom
        ? 'top'
        : 'bottom'
      const maxHorizontalSide = spaces.right > spaces.left
        ? 'right'
        : 'left'
      if (onlyOpposite) {
        const maxAvailableSide = ['right', 'left'].includes(preferredSide)
          ? maxHorizontalSide
          : maxVerticalSide
        setOpenSettings(maxAvailableSide, rootSize, bodySize, spaces, viewportSize)
      } else {
        const maxVerticalArea = Math.min(bodySize.height, spaces[maxVerticalSide])
          * Math.min(bodySize.width, viewportSize.width)
        const maxHorizontalArea = Math.min(bodySize.height, viewportSize.height)
          * Math.min(bodySize.width, spaces[maxHorizontalSide])
        setOpenSettings(maxVerticalArea >= maxHorizontalArea
          ? maxVerticalSide
          : maxHorizontalSide, rootSize, bodySize, spaces, viewportSize)
      }
    }

    setTimeout(afterOpen)
  }

  const onClose = () => {
    window.removeEventListener('resize', onClose, { capture: true, passive: true })
    window.removeEventListener('scroll', onScroll, { capture: true, passive: true })
    openSettings.value = null
    resizeObs.disconnect()
  }

  const onScroll = $event => {
    if (openingBody.value && !$event.composedPath().includes(openingBody.value)) {
      onScrollEvent()
      onClose()
    }
  }

  function onOpen(afterOpen = () => {}) {
    if (targetBody.value) {
      openSettings.value = {
        placement: preferredSide,
        style: 'top: -10000px; left: -10000px; visibility: hidden;' + minWidth()
      }
      nextTick(() => {
        calcPosition(afterOpen)
      })
    }
  }

  function onResize() {
    clearTimeout(resizeTimeoutId)
    resizeTimeoutId = setTimeout(() => {
      onOpen()
    })
  }

  return {
    openSettings,
    onOpen,
    onClose
  }
}
