import http from '../instance'
import { utils } from '../instance'

const getProfile = () => http.get('account/profile')

const editProfile = (organizationId, groups) => http.post('account/profile/edit', { organizationId, groups })

const resetPassword = (newPassword, token) =>
  http.post('/account/password/reset', {
    password: newPassword
  }, {
    headers: utils.addValidationHeader(token)
  })

export default {
  getProfile,
  editProfile,
  resetPassword
}
