import instance from '../instance'
import { composeMsg } from '../instance/utils'

const listenProjects = groupId => projectIds =>
  instance.sendMessage(groupId, composeMsg('LISTEN_PROJECTS', { projectIds }))

const stopListenProjects = groupId => () =>
  instance.sendMessage(groupId, composeMsg('STOP_LISTEN_PROJECTS'))

export default {
  listenProjects,
  stopListenProjects
}
