const RESOURCES = [{
  node: 'project',
  actions: ['UPDATE']
}, {
  node: 'user',
  actions: ['READ', 'UPDATE_USER', 'UPDATE_GROUP']
}, {
  node: 'dashboard',
  actions: ['READ', 'INTERACTION', 'UPDATE']
}, {
  node: 'tag',
  actions: ['READ', 'UPDATE_TAG', 'UPDATE_TAG_TEMPLATE']
}, {
  node: 'script',
  actions: ['READ', 'UPDATE']
}, {
  node: 'notification',
  actions: [
    'EXPORT',
    'READ',
    'UPDATE_TEMPLATES',
    'UPDATE_SYSTEM_TEMPLATES']
}, {
  node: 'history',
  actions: ['READ', 'EXPORT']
}]

const OUT_OF_ACCESS = 'NONE'

export default {
  OUT_OF_ACCESS,
  RESOURCES
}
