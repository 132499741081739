import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { opcua } from '@/api'
import { useNotifications } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const processing = ref(false)

  async function getServers() {
    const data = {}
    loading.value = true
    try {
      const response = await opcua.getServers($route.params.projectId)
      data.response = response.servers
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.opcua.http.getServers.error'))
      console.error('opc server\'s request error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function getServer(serverId) {
    const data = {}
    loading.value = true
    try {
      const response = await opcua.getServer(serverId)
      data.response = response.server
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.opcua.http.getServer.error'))
      console.error('OPC server request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function addServer({
    url,
    name,
    securityMode,
    securityPolicy,
    authType,
    login,
    password
  }) {
    const data = {}
    processing.value = true
    try {
      const response = await opcua.addServer({
        projectId: $route.params.projectId,
        url,
        name,
        securityMode,
        securityPolicy,
        authType,
        login,
        password
      })
      data.response = response.servers
      notificationsStore.showNotification('success', $t('api.opcua.http.addServer.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.opcua.http.addServer.error'))
      console.error('OPC server save request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function editServer(serverId, {
    url,
    name,
    securityMode,
    securityPolicy,
    authType,
    login,
    password
  }) {
    const data = {}
    processing.value = true
    try {
      const response = await opcua.editServer({
        serverId,
        url,
        name,
        securityMode,
        securityPolicy,
        authType,
        login,
        password
      })
      data.response = response.servers
      notificationsStore.showNotification('success', $t('api.opcua.http.editServer.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.opcua.http.editServer.error'))
      console.error('OPC server save request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteServer(serverId) {
    const data = {}
    processing.value = true
    try {
      await opcua.deleteServer(serverId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.opcua.http.deleteServer.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.opcua.http.deleteServer.error'))
      console.error('OPC server delete request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function getNodeVars(serverId, rootNodeId) {
    const data = {}
    loading.value = true
    try {
      const response = await opcua.getNodeVariablesOnLevel({
        serverId,
        rootNodeId
      })
      data.response = response.nodes
    } catch (e) {
      data.error = e
      if (e.code === 'SERVER_OFFLINE') {
        notificationsStore.showNotification('error', $t('api.opcua.http.getNodeVariablesOnLevel.errorServerOffline'))
      } else if (e.code === 'NOT_FOUND_SERVER') {
        notificationsStore.showNotification('error', $t('api.opcua.http.getNodeVariablesOnLevel.errorNotFoundServer'))
      } else if (e.code === 'REQUEST_TIMEOUT') {
        notificationsStore.showNotification('error', $t('api.opcua.http.getNodeVariablesOnLevel.errorRequestTimeout'))
      } else {
        notificationsStore.showNotification('error', $t('api.opcua.http.getNodeVariablesOnLevel.error'))
      }
      console.error('load opc catalog request error')
    } finally {
      loading.value = false
    }

    return data
  }


  return {
    loading,
    processing,
    getServers,
    getServer,
    addServer,
    editServer,
    deleteServer,
    getNodeVars
  }
}
