import { ref } from 'vue'
import { defineStore } from 'pinia'

import {
  useUser,
  useProjects,
  useModals,
  useNotifications,
  useScreenSettings,
  useTheme
} from '@/stores'

const useMain = defineStore('main', () => {
  const userStore = useUser()
  const projectsStore = useProjects()
  const modalsStore = useModals()
  const notificationsStore = useNotifications()
  const screenSettingsStore = useScreenSettings()
  const themeStore = useTheme()

  const initializing = ref()
  const isInitialized = ref()

  async function init() {
    initializing.value = true
    if (
      await userStore.init()
      && await projectsStore.init()
    ) {
      themeStore.init()
      screenSettingsStore.init()
      isInitialized.value = true
    }
    initializing.value = false

    return isInitialized.value
  }

  function destroy() {
    userStore.destroy()
    projectsStore.destroy()
    modalsStore.destroy()
    notificationsStore.destroy()
    isInitialized.value = false
  }

  return {
    isInitialized,
    initializing,
    init,
    destroy
  }
})

export default useMain
