const THEMES = {
  NAVIGATION_PRIMARY: 'navigation-primary',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TETRIARY: 'tetriary',
  TRANSPARENT: 'transparent'
}

const SIZES = {
  M: 'm',
  S: 's',
  XS: 'xs'
}

const ICON_PLACEMENT = {
  WHOLE: 'whole',
  BEFORE: 'before'
}

export {
  THEMES,
  SIZES,
  ICON_PLACEMENT
}
