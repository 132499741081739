import http from '../instance'

const getNodeVariablesOnLevel = ({ serverId, rootNodeId }) => {
  const params = {}
  if (rootNodeId || rootNodeId === 0) {
    params.rootNodeId = rootNodeId
  }

  return http.get(`opc-servers/${serverId}/nodes`, { params })
}

const getServers = projectId => {
  return http.get('opc-servers', { params: { projectId } })
}

const getServer = serverId => {
  return http.get(`opc-servers/${serverId}`)
}

const addServer = ({
  projectId,
  url,
  name,
  securityMode,
  securityPolicy,
  authType,
  login,
  password
}) => {
  return http.post('opc-servers', {
    projectId,
    url,
    name,
    securityMode,
    securityPolicy,
    authType,
    login,
    password
  })
}

const editServer = ({
  serverId,
  url,
  name,
  securityMode,
  securityPolicy,
  authType,
  login,
  password
}) => {
  return http.post(`opc-servers/${serverId}/edit`, {
    url,
    name,
    securityMode,
    securityPolicy,
    authType,
    login,
    password
  })
}

const deleteServer = serverId => {
  return http.post(`opc-servers/${serverId}/delete`)
}

export default {
  getNodeVariablesOnLevel,
  getServers,
  getServer,
  addServer,
  editServer,
  deleteServer
}
