import http from '../instance'

const INTENTIONS = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD'
}

const getValidationToken = (intent, password) => {
  if (!(intent in INTENTIONS)) {
    return Promise.reject(new Error('Bad request intent'))
  }

  return http.post('/password-validation-token', {
    intent,
    password
  })
}

export default {
  INTENTIONS,
  getValidationToken
}
