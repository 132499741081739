import { defineStore } from 'pinia'
import { ref } from 'vue'

import { getStateMenu, updateStateMenu, getTagCatalogWidth, updateTagCatalogWidth } from '@/utils/userScreenSettings.js'

const useScreenSettings = defineStore('screenSettings', () => {
  const isNarrow = ref(false)
  const navCatalogBlockWidth = ref('260px')

  function init() {
    getSideMenuState()
    getTagCatalogWidthState()
  }

  function getSideMenuState() {
    const oldIsNarrow = getStateMenu('state-side-menu')
    if (oldIsNarrow) isNarrow.value = oldIsNarrow === 'true' || false
  }

  function updateSideMenuState(state) {
    updateStateMenu(state)
    isNarrow.value = !!state
  }

  function getTagCatalogWidthState() {
    const oldWidth = getTagCatalogWidth('tags-catalog-width')
    if (oldWidth) navCatalogBlockWidth.value = oldWidth + 'px'
  }

  function updateTagCatalogWidthState(width) {
    updateTagCatalogWidth(width)
    navCatalogBlockWidth.value = width + 'px'
  }

  return {
    isNarrow,
    navCatalogBlockWidth,
    init,
    updateSideMenuState,
    updateTagCatalogWidthState
  }
})

export default useScreenSettings
