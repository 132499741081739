import http from '../instance'

const getTemplates = projectId => {
  return http.get('/tag-templates', { params: { projectId } })
}

const getTags = tagTemplateId => {
  return http.get(`/tag-templates/${tagTemplateId}/tags`)
}

const createTagsTemplate = ({
  projectId,
  name,
  description = null,
  tagIds = null,
  tags = null
}) => {
  const params = {
    projectId,
    name,
    description,
    tagIds,
    tags
  }
  return http.post('/tag-templates', params)
}

const deleteTags = tagTemplateId => {
  return http.post(`/tag-templates/${tagTemplateId}/delete`)
}

export default {
  createTagsTemplate,
  getTemplates,
  getTags,
  deleteTags
}
