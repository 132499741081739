import http from '../instance'

const getNodes = projectId => {
  return http.get('/component-nodes', {
    params: {
      projectId
    }
  })
}

const searchNodes = ({ projectId, q, page, size, type = null, sort = null, sortDirection = null }) => {
  let params = {
    projectId,
    q,
    page,
    size
  }
  if (type) params.type = type
  if (sort) params.sort = sort
  if (sort && sortDirection) params.sortDirection = sortDirection
  return http.get('/component-nodes/search', { params })
}

const addNode = ({ projectId, name, parentNodeId }) => {
  return http.post('/component-nodes', { projectId, name, parentNodeId })
}

const editNode = ({ componentNodeId, name }) => {
  return http.post(`/component-nodes/${componentNodeId}/edit`, {
    name
  })
}

const deleteNode = ({ componentNodeId, safeChilds }) => {
  return http.post(`/component-nodes/${componentNodeId}/delete`, { safeChilds })
}

const moveInNode = ({ componentNodeId, moveNodeId, tagIds }) => {
  let params = {
    componentNodeId: null,
    tagIds: null
  }
  if (moveNodeId) {
    params.componentNodeId = moveNodeId
  }

  if (tagIds && tagIds.length) {
    params.tagIds = tagIds
  }

  if (!params.componentNodeId && !params.tagIds) return
  return http.post(`/component-nodes/${componentNodeId}/move-into`, params)
}

export default {
  getNodes,
  addNode,
  editNode,
  deleteNode,
  searchNodes,
  moveInNode
}
