import http from '../instance'

const getNotifications = signal => ({
  projectId,
  q,
  type,
  severity,
  state,
  templateIds,
  sentAt,
  sentFrom,
  sentTo,
  startAt,
  startFrom,
  startTo,
  sort,
  sortDirection,
  page,
  size
}) => {
  const params = { projectId }

  if (q) {
    params.q = q
  }

  if (type) {
    params.type = type
  }

  if (severity) {
    params.severity = severity
  }

  if (state) {
    params.state = state
  }

  if (templateIds && templateIds.length) {
    params.templateIds = templateIds
  }

  if (sentAt) {
    params.sentAt = sentAt
  }

  if (sentFrom) {
    params.sentFrom = sentFrom
  }

  if (sentTo) {
    params.sentTo = sentTo
  }

  if (startAt) {
    params.startAt = startAt
  }

  if (startFrom) {
    params.startFrom = startFrom
  }

  if (startTo) {
    params.startTo = startTo
  }

  if (sort) {
    params.sort = sort
  }

  if (sortDirection) {
    params.sortDirection = sortDirection
  }

  if ((page || page === 0) && size) {
    params.page = page
    params.size = size
  }
  return http.get('/notifications', { signal, params })
}

const getNotificationsBySource = ({
  triggerType,
  triggerTypeId,
  projectId,
  q,
  type,
  severity,
  state,
  templateIds,
  sentAt,
  sentFrom,
  sentTo,
  startAt,
  startFrom,
  startTo,
  sort,
  sortDirection,
  page,
  size
}) => {
  const params = { projectId }

  if (q) {
    params.q = q
  }

  if (type) {
    params.type = type
  }

  if (severity) {
    params.severity = severity
  }

  if (state) {
    params.state = state
  }

  if (templateIds && templateIds.length) {
    params.templateIds = templateIds
  }

  if (sentAt) {
    params.sentAt = sentAt
  }

  if (sentFrom) {
    params.sentFrom = sentFrom
  }

  if (sentTo) {
    params.sentTo = sentTo
  }

  if (startAt) {
    params.startAt = startAt
  }

  if (startFrom) {
    params.startFrom = startFrom
  }

  if (startTo) {
    params.startTo = startTo
  }

  if (sort) {
    params.sort = sort
  }

  if (sortDirection) {
    params.sortDirection = sortDirection
  }

  if ((page || page === 0) && size) {
    params.page = page
    params.size = size
  }

  return http.get(`/notifications/source/${triggerType}/${triggerTypeId}`, { params })
}

const getNotificationInfo = notificationId =>
  http.get(`/notifications/${notificationId}`)

const getNotificationTemplates = ({
  projectId,
  page,
  size
}) =>
  http.get('/notifications/templates', { params: { projectId, page, size } })

const changeNotificationStatus = ({
  projectId,
  state,
  notificationIds
}) => {
  const params = { projectId, state }

  if (notificationIds) {
    params.notificationIds = notificationIds
  }
  return http.post('/notifications/set-read-state', params)
}

const exportNotifications = ({
  projectId,
  q,
  type,
  severity,
  state,
  templateIds,
  sentAt,
  sentFrom,
  sentTo,
  startAt,
  startFrom,
  startTo,
  sort,
  sortDirection,
  sourceType,
  sourceId
}) => {

  const params = { projectId }

  if (q) {
    params.q = q
  }

  if (type) {
    params.type = type
  }

  if (severity) {
    params.severity = severity
  }

  if (state) {
    params.state = state
  }

  if (templateIds && templateIds.length) {
    params.templateIds = templateIds
  }

  if (sentAt) {
    params.sentAt = sentAt
  }

  if (sentFrom) {
    params.sentFrom = sentFrom
  }

  if (sentTo) {
    params.sentTo = sentTo
  }

  if (startAt) {
    params.startAt = startAt
  }

  if (startFrom) {
    params.startFrom = startFrom
  }

  if (startTo) {
    params.startTo = startTo
  }

  if (sort) {
    params.sort = sort
  }

  if (sortDirection) {
    params.sortDirection = sortDirection
  }

  if (sourceType) {
    params.sourceType = sourceType

    if (sourceId) {
      params.sourceId = sourceId
    }
  }

  return http.post('/notifications/export', params)
}

export default {
  getNotifications,
  getNotificationsBySource,
  getNotificationInfo,
  getNotificationTemplates,
  changeNotificationStatus,
  exportNotifications
}
