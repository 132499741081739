import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { history } from '@/api'
import { useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const loadingExport = ref(false)
  let historyTagsController = new AbortController()

  async function getHistoryTags(params, pagination) {
    if (loading.value) {
      cancelHistoryTagsRequests()
    }

    let isAborted
    const data = {}
    loading.value = true
    try {
      const response = await history.getHistoryTags(historyTagsController.signal)({
        projectId: $route.params.projectId,
        q: params.q,
        type: params.type,
        event: params.event,
        tags: params.tags,
        sort: params.sort,
        sortDirection: params.sortDirection,
        page: pagination.page,
        size: pagination.size,
        dataTypes: params.dataTypes,
        period: params.period,
        periodFrom: params.periodFrom,
        periodTo: params.periodTo,
        state: params.state
      })
      data.response = response.tags
      data.total = response.total
    } catch (e) {
      if (!e.isAborted) {
        data.error = e
        notificationsStore.showNotification('error', $t('api.history.http.getHistoryTags.error'))
        console.error('history tags load request error', e)
      } else {
        isAborted = true
      }
    } finally {
      if (!isAborted) {
        loading.value = false
      }
    }

    return data
  }

  function cancelHistoryTagsRequests() {
    historyTagsController.abort()
    historyTagsController = new AbortController()
  }

  async function exportHistoryTags(params) {
    const data = {}
    loadingExport.value = true
    try {
      const response = await history.exportHistoryTags({
        projectId: $route.params.projectId,
        q: params.q,
        sort: params.sort,
        tags: params.tags,
        type: params.type,
        dataTypes: params.dataTypes,
        event: params.event,
        period: params.period,
        sortDirection: params.sortDirection,
        periodFrom: params.periodFrom,
        periodTo: params.periodTo,
        state: params.state
      })
      data.response = response.link
      if (response.isDataExceeded) {
        notificationsStore.showNotification(
          'success',
          $t('api.history.http.exportHistoryTags.success')
        )
      }
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.history.http.exportHistoryTags.error'))
      console.error('history tags export request error', e)
    } finally {
      loadingExport.value = false
    }

    return data
  }


  return {
    loading,
    loadingExport,
    getHistoryTags,
    exportHistoryTags
  }
}
