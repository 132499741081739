const TYPES = {
  USER: 'USER',
  SYSTEM: 'SYSTEM'
}

const TYPE_TO_PREFIX = {
  [TYPES.USER]: 'user',
  [TYPES.SYSTEM]: 'system'
}

const TYPE_TRANSLATES = {
  [TYPES.SYSTEM]: `domains.notification.prefixType.${TYPES.SYSTEM}`,
  [TYPES.USER]: `domains.notification.prefixType.${TYPES.USER}`
}

const SEVERITIES = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ALARM: 'ALARM'
}

const SEVERITY_TRANSLATES = {
  [SEVERITIES.INFO]: `domains.notification.severities.${SEVERITIES.INFO}`,
  [SEVERITIES.WARNING]: `domains.notification.severities.${SEVERITIES.WARNING}`,
  [SEVERITIES.ALARM]: `domains.notification.severities.${SEVERITIES.ALARM}`
}

const SEVERITY_ICONS = {
  [SEVERITIES.INFO]: 'notifications-info',
  [SEVERITIES.WARNING]: 'notifications-warning',
  [SEVERITIES.ALARM]: 'notifications-alarm'
}

const STATES = {
  NEW: 'NEW',
  READ: 'READ'
}

const STATE_TRANSLATES = {
  [STATES.NEW]: `domains.notification.states.${STATES.NEW}`,
  [STATES.READ]: `domains.notification.states.${STATES.READ}`
}

const STATE_ICONS = {
  [STATES.NEW]: 'close-message',
  [STATES.READ]: 'open-message'
}

const TRIGGERS = {
  TAG: 'TAG',
  SCRIPT: 'SCRIPT'
}

const TRIGGER_TRANSLATES = {
  [TRIGGERS.TAG]: `domains.notification.triggers.${TRIGGERS.TAG}`,
  [TRIGGERS.SCRIPT]: `domains.notification.triggers.${TRIGGERS.SCRIPT}`
}

const CHANNELS = {
  EMAIL: 'EMAIL',
  TELEGRAM: 'TELEGRAM',
  WEB_PUSH: 'WEB_PUSH',
  MOBILE_PUSH: 'MOBILE_PUSH'
}

const CHANNEL_TRANSLATES = {
  [CHANNELS.EMAIL]: `domains.notification.channels.${CHANNELS.EMAIL}`,
  [CHANNELS.TELEGRAM]: `domains.notification.channels.${CHANNELS.TELEGRAM}`,
  [CHANNELS.WEB_PUSH]: `domains.notification.channels.${CHANNELS.WEB_PUSH}`,
  [CHANNELS.MOBILE_PUSH]: `domains.notification.channels.${CHANNELS.MOBILE_PUSH}`
}

const CHANNEL_ICONS = {
  [CHANNELS.EMAIL]: 'notifications-email',
  [CHANNELS.TELEGRAM]: 'notifications-telegram',
  [CHANNELS.WEB_PUSH]: 'notifications-web-push',
  [CHANNELS.MOBILE_PUSH]: 'notifications-mobile-push'
}

const PERIODS = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_WEEK: 'LAST_WEEK',
  PERIOD: 'PERIOD',
  NONE: 'NONE'
}

const TYPES_SORT = {
  startAt: 'START_AT',
  sentAt: 'SENT_AT',
  source: 'SOURCE'
}

const PUSH_TYPES = {
  WEB: 'WEB',
  MOBILE: 'MOBILE'
}

// key is time in minutes
const TIMEZONES = {
  0: 'domains.notification.timezones.0',
  60: 'domains.notification.timezones.60',
  120: 'domains.notification.timezones.120',
  180: 'domains.notification.timezones.180',
  240: 'domains.notification.timezones.240',
  300: 'domains.notification.timezones.300',
  360: 'domains.notification.timezones.360',
  420: 'domains.notification.timezones.420',
  480: 'domains.notification.timezones.480',
  540: 'domains.notification.timezones.540',
  600: 'domains.notification.timezones.600',
  660: 'domains.notification.timezones.660',
  720: 'domains.notification.timezones.720'
}

const TIMEZONE_LIST = Object.keys(TIMEZONES).reduce((acc, time) => {
  acc.push({
    id: +time,
    title: TIMEZONES[time]
  })
  return acc
}, [])

export default {
  TYPES,
  TYPE_TO_PREFIX,
  TYPE_TRANSLATES,
  CHANNELS,
  CHANNEL_TRANSLATES,
  CHANNEL_ICONS,
  STATES,
  STATE_TRANSLATES,
  STATE_ICONS,
  SEVERITIES,
  SEVERITY_ICONS,
  SEVERITY_TRANSLATES,
  TRIGGERS,
  TRIGGER_TRANSLATES,
  PERIODS,
  TYPES_SORT,
  PUSH_TYPES,
  TIMEZONES,
  TIMEZONE_LIST
}
