import { LRUCache } from 'lru-cache'

const cacheAdapter = adapter => {
  const cache = new LRUCache({
    ttl: 5 * 1000,
    max: 100,
    updateAgeOnGet: false,
    updateAgeOnHas: false
  })

  return config => {
    if (config.method === 'get') {
      const params = new URLSearchParams(config.params)
      const index = `${config.url}?${params.toString()}`
      if (cache.has(index)) {
        return cache.get(index)
      }

      const request = adapter(config).catch(e => {
        cache.delete(index)
        throw e
      })
      cache.set(index, request)
      return request
    }

    return adapter(config)
  }
}

export {
  cacheAdapter
}
