import { WIDGETS } from '@/common/constants'

function getWidgetColors() {
  const styles = getComputedStyle(document.documentElement)
  return {
    [WIDGETS.VERBOSE_COLORS.FANDANGO]: styles.getPropertyValue('--widgets-color-extra-fandango'),
    [WIDGETS.VERBOSE_COLORS.CELESTIAL_BLUE]: styles.getPropertyValue('--widgets-color-extra-celestial-blue'),
    [WIDGETS.VERBOSE_COLORS.SELECTIVE_YELLOW]: styles.getPropertyValue('--widgets-color-extra-selective-yellow'),
    [WIDGETS.VERBOSE_COLORS.EMERALD]: styles.getPropertyValue('--widgets-color-extra-emerald'),
    [WIDGETS.VERBOSE_COLORS.IMPERIAL_RED]: styles.getPropertyValue('--widgets-color-extra-imperial-red'),
    [WIDGETS.VERBOSE_COLORS.GREY]: styles.getPropertyValue('--widgets-color-extra-state-grey'),
    [WIDGETS.VERBOSE_COLORS.ROBIN_EGG_BLUE]: styles.getPropertyValue('--widgets-color-extra-robin-egg-blue'),
    [WIDGETS.VERBOSE_COLORS.SAVOY_BLUE]: styles.getPropertyValue('--widgets-color-extra-savoy-blue'),
    [WIDGETS.VERBOSE_COLORS.TEAL]: styles.getPropertyValue('--widgets-color-extra-teal'),
    [WIDGETS.VERBOSE_COLORS.NIGHT]: styles.getPropertyValue('--widgets-color-extra-night'),
    [WIDGETS.VERBOSE_COLORS.PRINECTON_ORANGE]: styles.getPropertyValue('--widgets-color-extra-prinecton-orange'),
    scaleOffline: styles.getPropertyValue('--widgets-color-extra-scale-offline'),
    scaleSuccess: styles.getPropertyValue('--widgets-color-extra-scale-success'),
    scaleAttention: styles.getPropertyValue('--widgets-color-extra-scale-attention'),
    scaleError: styles.getPropertyValue('--widgets-color-extra-scale-error'),
    scaleFilled: styles.getPropertyValue('--widgets-color-extra-scale-filled')
  }
}

export {
  getWidgetColors
}
