import http from '../instance'

const login = ({ login, password }) =>
  http.post('/auth/signin', {
    login: {
      type: 'EMAIL',
      value: login
    },
    password
  })

const refreshToken = refreshToken =>
  http.post('/auth/refresh', { refreshToken })

const logout = () => http.post('/auth/signout')

export default {
  login,
  refreshToken,
  logout
}
