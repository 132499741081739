import { v4 as uuid } from 'uuid'

const DEVICE_KEY = 'deviceId'
const getDeviceId = () => {
  let deviceId = localStorage.getItem(DEVICE_KEY)
  if (!deviceId) {
    deviceId = uuid()
    localStorage.setItem(DEVICE_KEY, deviceId)
  }

  return deviceId
}

const SESSION_KEY = 'session'
/**
 * @typedef Session
 * @property {string} accessToken - access token of current session
 * @property {string} refreshToken - refresh token of current session
 * @property {string} expirationTime - expiration time date in seconds
 * Return current session of app
 * @returns {Session|null}
 */
const getSession = () => {
  let session = localStorage.getItem(SESSION_KEY)
  if (session) {
    try {
      session = JSON.parse(session)
    } catch (e) {
      console.error('Session parsing error')
    }
  }
  return session?.accessToken && session?.refreshToken && session?.expirationTime
    ? session
    : null
}

/**
 * Set session data to local storage
 * @param {Session} session
 */
const setSession = ({ accessToken, refreshToken, expirationTime }) =>
  localStorage.setItem(SESSION_KEY, JSON.stringify({
    accessToken,
    refreshToken,
    expirationTime
  }))

const clearSession = () => localStorage.removeItem(SESSION_KEY)

export {
  getDeviceId,
  getSession,
  setSession,
  clearSession
}
