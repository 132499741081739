import { ref } from 'vue'

import { validation } from '@/api'

export default function () {
  const loading = ref()

  async function getValidationToken(password) {
    const data = {}
    loading.value = true
    try {
      const response = await validation.getValidationToken(validation.INTENTIONS.CHANGE_PASSWORD, password)
      data.response = response.validation
    } catch (e) {
      data.error = e
      console.error(e)
    } finally {
      loading.value = false
    }

    return data
  }

  return {
    loading,
    getValidationToken
  }
}
