import { v4 as uuid } from 'uuid'
import { getDeviceId } from '@/utils/session'

/**
 * @typedef {Object} SocketMessage
 * @property {string} action - msg action
 * @property {string} deviceId - uuid from cookie for user determination
 * @property {string} requestId - uuid for request determination
 * @property {Object=} data - msg data
 */
/**
 * Create socket message
 * @param {SocketMessage.action} action
 * @param {Object} data
 * @returns {SocketMessage}
 */
const composeMsg = (action, data) => {
  const msg = {
    action,
    deviceId: getDeviceId(),
    requestId: uuid()
  }

  if (data) {
    msg.data = data
  }

  return msg
}

export {
  composeMsg
}
