import instance from '../instance'
import { composeMsg } from '../instance/utils'

const listenDashboard = groupId => dashboardId =>
  instance.sendMessage(groupId, composeMsg('LISTEN_DASHBOARD', { dashboardId }))

const stopListenDashboard = groupId => () =>
  instance.sendMessage(groupId, composeMsg('STOP_LISTEN_DASHBOARD'))

export default {
  listenDashboard,
  stopListenDashboard
}
