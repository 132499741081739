import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { mqttGateways } from '@/api'
import { useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loadingList = ref(false)
  const loadingClient = ref(false)
  const processingList = ref(false)
  const processingClient = ref(false)

  async function getGateways() {
    const data = {}
    loadingList.value = true
    try {
      data.response = await mqttGateways.getGateways($route.params.projectId)
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.getGateways.error'))
      console.error('gateway\'s request error')
    } finally {
      loadingList.value = false
    }

    return data
  }

  async function addGateway({ name }) {
    const data = {}
    processingList.value = true
    try {
      const response = await mqttGateways.addGateway({
        projectId: $route.params.projectId,
        name
      })
      data.response = response.mqttGateways
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.addGateway.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.addGateway.error'))
      console.error('gateway save request error', e)
    } finally {
      processingList.value = false
    }

    return data
  }

  async function editGateway(mqttGatewayId, { name }) {
    const data = {}
    processingList.value = true
    try {
      const response = await mqttGateways.editGateway({
        mqttGatewayId,
        name
      })
      data.response = response.mqttGateways
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.editGateway.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.editGateway.error'))
      console.error('gateway save request error', e)
    } finally {
      processingList.value = false
    }

    return data
  }

  async function deleteGateway(mqttGatewayId) {
    const data = {}
    processingList.value = true
    try {
      const response = await mqttGateways.deleteGateway(mqttGatewayId)
      data.response = response.mqttGateways
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.deleteGateway.success'))
    } catch(e) {
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.deleteGateway.error'))
      console.error('delete gateway/s request error', e)
    } finally {
      processingList.value = false
    }

    return data
  }

  async function getGatewaysClient() {
    const data = {}
    loadingClient.value = true
    try {
      const response = await mqttGateways.getGatewaysClient($route.params.projectId)
      data.response = response.client
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.getGatewaysClient.error'))
      console.error('gateway\'s request error')
    } finally {
      loadingClient.value = false
    }

    return data
  }

  async function addGatewaysClient({
    userName,
    password
  }) {
    const data = {}
    processingClient.value = true
    try {
      const response = await mqttGateways.addGatewaysClient({
        projectId: $route.params.projectId,
        userName,
        password
      })
      data.response = response.client
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.addGatewaysClient.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.addGatewaysClient.error'))
      console.error('gateway\'s request error')
    } finally {
      processingClient.value = false
    }

    return data
  }

  async function editGatewaysClient({
    userName,
    password
  }) {
    const data = {}
    processingClient.value = true
    try {
      const response = await mqttGateways.editGatewaysClient({
        projectId: $route.params.projectId,
        userName,
        password
      })
      data.response = response.client
      notificationsStore.showNotification('success', $t('api.mqttGateways.http.editGatewaysClient.error'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.mqttGateways.http.editGatewaysClient.error'))
      console.error('gateway\'s request error')
    } finally {
      processingClient.value = false
    }

    return data
  }

  return {
    loadingList,
    loadingClient,
    processingList,
    getGateways,
    addGateway,
    editGateway,
    deleteGateway,
    getGatewaysClient,
    addGatewaysClient,
    editGatewaysClient
  }
}
