import http from '../instance'

const getDashboardVersions = dashboardId =>
  http.get(`/dashboards/${dashboardId}/versions`)

const getDashboardVersion = signal => versionId =>
  http.get(`/dashboard-versions/${versionId}`, { signal })

const getDashboardLastDetailVersion = dashboardId =>
  http.get(`/dashboards/${dashboardId}/versions/last-detail`)

const createDashboardVersion = (dashboardId, { title, widgets }) =>
  http.post(`/dashboards/${dashboardId}/versions`, { title, widgets })

const deleteDashboardVersion = versionId =>
  http.post(`/dashboard-versions/${versionId}/delete`)

export default {
  getDashboardVersions,
  getDashboardVersion,
  getDashboardLastDetailVersion,
  createDashboardVersion,
  deleteDashboardVersion
}
