import { ACCESS } from '@/common/constants'

const buildProjectAccessModel = ({ [ACCESS.RESOURCES.PROJECT]: access }) => ({
  canAdd: access[ACCESS.ACTIONS.CREATE]
})

const buildUsersAccessModel = ({ [ACCESS.RESOURCES.USER]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE],
  canInteract: access[ACCESS.ACTIONS.INTERACTION]
})

const buildUserGroupsAccessModel = ({ [ACCESS.RESOURCES.USER_GROUP]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE]
})

const buildDashboardAccessModel = ({ [ACCESS.RESOURCES.DASHBOARD]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE],
  canPin: access[ACCESS.ACTIONS.SET_ACTIVE],
  canInteract: access[ACCESS.ACTIONS.INTERACTION],
  canExport: access[ACCESS.ACTIONS.EXPORT],
  canImport: access[ACCESS.ACTIONS.IMPORT]
})

const buildTagAccessModel = ({
  [ACCESS.RESOURCES.TAG]: accessTag,
  [ACCESS.RESOURCES.TAG_TEMPLATE]: accessTagTemplate
}) => ({
  canRead: accessTag[ACCESS.ACTIONS.READ],
  canAddTag: accessTag[ACCESS.ACTIONS.CREATE],
  canAddNode: accessTag[ACCESS.ACTIONS.CREATE],
  canEditTag: accessTag[ACCESS.ACTIONS.UPDATE],
  canEditNode: accessTag[ACCESS.ACTIONS.UPDATE],
  canDeleteTag: accessTag[ACCESS.ACTIONS.DELETE],
  canDeleteNode: accessTag[ACCESS.ACTIONS.DELETE],
  canReadTagTemplates: accessTagTemplate[ACCESS.ACTIONS.READ],
  canAddTagTemplates: accessTagTemplate[ACCESS.ACTIONS.CREATE],
  canEditTagTemplates: accessTagTemplate[ACCESS.ACTIONS.UPDATE],
  canDeleteTagTemplates: accessTagTemplate[ACCESS.ACTIONS.DELETE]
})

const buildScaleAccessModel = ({ [ACCESS.RESOURCES.SCALE]: access }) => ({
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE]
})

const buildGatewayAccessModel = ({ [ACCESS.RESOURCES.GATEWAY]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE]
})


const buildMqttGatewayAccessModel = ({ [ACCESS.RESOURCES.MQTT_GATEWAY]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE]
})

const buildOpcServerAccessModel = ({ [ACCESS.RESOURCES.OPC_SERVER]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE]
})

const buildScriptAccessModel = ({ [ACCESS.RESOURCES.SCRIPT]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAddScript: access[ACCESS.ACTIONS.CREATE],
  canAddNode: access[ACCESS.ACTIONS.CREATE],
  canEditScript: access[ACCESS.ACTIONS.UPDATE],
  canEditNode: access[ACCESS.ACTIONS.UPDATE],
  canDeleteNode: access[ACCESS.ACTIONS.DELETE],
  canDeleteScript: access[ACCESS.ACTIONS.DELETE],
  canExportScript: access[ACCESS.ACTIONS.EXPORT],
  canImportScript: access[ACCESS.ACTIONS.IMPORT],
  canRunScript: access[ACCESS.ACTIONS.INTERACTION]
})

const buildNotificationAccessModel = ({ [ACCESS.RESOURCES.NOTIFICATION_LIST]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canExport: access[ACCESS.ACTIONS.EXPORT]
})

const buildNotificationTemplateAccessModel = ({
  [ACCESS.RESOURCES.NOTIFICATION_TEMPLATE]: templateAccess,
  [ACCESS.RESOURCES.NOTIFICATION_TEMPLATE_USER]: userTemplateAccess,
  [ACCESS.RESOURCES.NOTIFICATION_TEMPLATE_SYSTEM]: systemTemplateAccess
}) => ({
  canRead: templateAccess[ACCESS.ACTIONS.READ],
  canAddUserTemplate: userTemplateAccess[ACCESS.ACTIONS.CREATE],
  canEditUserTemplate: userTemplateAccess[ACCESS.ACTIONS.UPDATE],
  canDeleteUserTemplate: userTemplateAccess[ACCESS.ACTIONS.DELETE],
  canEditSystemTemplate: systemTemplateAccess[ACCESS.ACTIONS.UPDATE]
})

const buildHistoryAccessModel = ({ [ACCESS.RESOURCES.HISTORY]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canExport: access[ACCESS.ACTIONS.EXPORT]
})

const buildSettingsAccessModel = ({ [ACCESS.RESOURCES.SETTINGS]: access }) => ({
  canRead: access[ACCESS.ACTIONS.READ],
  canAdd: access[ACCESS.ACTIONS.CREATE],
  canEdit: access[ACCESS.ACTIONS.UPDATE],
  canDelete: access[ACCESS.ACTIONS.DELETE]
})

export {
  // organization resources
  buildProjectAccessModel,
  buildUsersAccessModel,
  buildUserGroupsAccessModel,
  // project resources
  buildDashboardAccessModel,
  buildTagAccessModel,
  buildScaleAccessModel,
  buildGatewayAccessModel,
  buildMqttGatewayAccessModel,
  buildOpcServerAccessModel,
  buildScriptAccessModel,
  buildNotificationAccessModel,
  buildNotificationTemplateAccessModel,
  buildHistoryAccessModel,
  buildSettingsAccessModel
}
