import { computed, reactive, readonly, ref, shallowRef } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { defineStore } from 'pinia'

import { APP } from '@/common/constants'
import { useAccessApi, useAccountApi, useAuthApi, useValidationApi } from '@/composables/api/http'
import {
  getSession,
  setSession,
  clearSession
} from '@/utils/session'
import {
  buildProjectAccessModel,
  buildUsersAccessModel,
  buildUserGroupsAccessModel,
  buildDashboardAccessModel,
  buildTagAccessModel,
  buildScaleAccessModel,
  buildGatewayAccessModel,
  buildMqttGatewayAccessModel,
  buildOpcServerAccessModel,
  buildScriptAccessModel,
  buildNotificationAccessModel,
  buildNotificationTemplateAccessModel,
  buildHistoryAccessModel,
  buildSettingsAccessModel
} from '@/utils/accessResources'

const EXPIRATION_TIME_OFFSET_SEC = 50

const useUser = defineStore('user', () => {
  const route = useRoute()
  const router = useRouter()
  const authApi = reactive(useAuthApi())
  const accessApi = reactive(useAccessApi())
  const accountApi = reactive(useAccountApi())
  const validationApi = reactive(useValidationApi())

  const profile = shallowRef()
  const isPasswordChangeRequired = ref()
  const projectAccessMap = ref(new Map())

  const profileOrganization = computed(() => profile.value?.organizations?.[0])
  const organizationAccess = computed(() => profileOrganization.value?.access)
  const profileModel = readonly(computed(() => {
    if (!profile.value) return

    const data = {
      id: profile.value.userId,
      firstName: profile.value.firstName,
      middleName: profile.value.middleName,
      lastName: profile.value.lastName,
      email: profile.value.email,
      notifications: profile.value.notifications
    }
    if (profile.value.organizations?.[0]) {
      data.isOwner = profile.value.organizations[0].isOwner
      data.groups = profile.value.organizations[0].groups
      data.organization = {
        isIndividualEntrepreneur: profile.value.organizations[0].isIndividualEntrepreneur,
        activityType: profile.value.organizations[0].activityType,
        timezone: profile.value.organizations[0].timezone,
        legalName: profile.value.organizations[0].organizationLegalName,
        legalAddress: profile.value.organizations[0].organizationLegalAddress,
        postAddress: profile.value.organizations[0].organizationPostAddress
      }
    }

    return data
  }))

  async function init() {
    if (!hasSession()) {
      return false
    }

    const data = await accountApi.getProfile()
    if (data.response) {
      profile.value = data.response
    }

    return !!data.response && profileOrganization.value || !data.error
  }

  function destroy() {
    profile.value = null
    isPasswordChangeRequired.value = false
    projectAccessMap.value.clear()
  }

  function setAuthSession(session) {
    const currentTimeInSeconds = Math.ceil(Date.now() / 10 ** 3)
    setSession({
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
      expirationTime: currentTimeInSeconds + session.lifetime
    })
  }

  async function login(login, password) {
    if (!login || !password) {
      return {
        response: false
      }
    }

    const data = await authApi.login(login, password)
    if (data.response) {
      setAuthSession(data.response.session)
      if (data.response.isPasswordChangeRequired) {
        isPasswordChangeRequired.value = true
      }
    }

    return {
      response: !!data.response,
      error: data.error
    }
  }

  async function logout(withoutRequest, withoutRedirect) {
    if (authApi.processingLogout) {
      return false
    }

    if (
      withoutRequest
      || (await authApi.logout()).response
    ) {
      clearSession()
    }

    if (!withoutRedirect) {
      router.replace({ name: APP.PAGES.AUTH })
    }
  }

  function updateSession() {
    return navigator.locks.request('auth-update', async () => {
      const session = getSession()
      if (!session?.refreshToken) return false
      if (validateSession()) return true

      const data = await authApi.refresh(session.refreshToken)
      if (data.response) {
        setAuthSession(data.response)
      }

      return data.response
    })
  }

  async function editProfile(groups) {
    const data = await accountApi.editProfile(getOrganizationId(), groups)
    if (data.response) {
      profile.value = data.response
    }

    return {
      response: !!data.response,
      error: data.error
    }
  }

  async function updatePassword(password, newPassword) {
    let data = await validationApi.getValidationToken(password)
    if (data.response) {
      data = await accountApi.resetPassword(newPassword, data.response.token)
      if (data.response) {
        setAuthSession(data.response)
      }
    }

    return {
      response: !!data.response,
      error: data.error
    }
  }

  function setNotifications(notifications) {
    profile.value = {
      ...profile.value,
      notifications
    }
  }

  function validateSession() {
    const session = getSession()
    if (!Number.isInteger(+session?.expirationTime)) {
      return false
    }

    const currentTimeInSeconds = Math.ceil(Date.now() / 10 ** 3)
    const remainLifeTime = session.expirationTime - currentTimeInSeconds - EXPIRATION_TIME_OFFSET_SEC
    return remainLifeTime > 0
  }

  function hasSession() {
    return !!getSession()
  }

  function getAccessToken() {
    const session = getSession()
    return session?.accessToken
  }

  function getOrganizationId() {
    return profileOrganization.value?.id
  }

  function getOrganizationProjectAccess() {
    if (!organizationAccess.value) return {}
    const access = organizationAccess.value || {}
    return buildProjectAccessModel(access)
  }

  function getUsersAccess() {
    if (!organizationAccess.value) return {}
    const access = organizationAccess.value || {}
    return buildUsersAccessModel(access)
  }

  function getUserGroupsAccess() {
    if (!organizationAccess.value) return {}
    const access = organizationAccess.value || {}
    return buildUserGroupsAccessModel(access)
  }

  async function getProjectAccess(projectId) {
    if (authApi.loading) {
      return
    }

    if (projectAccessMap.value.has(projectId)) {
      return projectAccessMap.value.get(projectId)
    }

    const data = await accessApi.getProjectAccess(projectId)
    if (data.response) {
      projectAccessMap.value.set(projectId, data.response)
    }

    return !!data.response
  }

  function getProjectResAccess(projectId) {
    return projectAccessMap.value.get(projectId) || {}
  }

  function getDashboardAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildDashboardAccessModel(access)
  }

  function getTagAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildTagAccessModel(access)
  }

  function getScaleAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildScaleAccessModel(access)
  }

  function getGatewayAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildGatewayAccessModel(access)
  }

  function getMqttGatewayAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildMqttGatewayAccessModel(access)
  }

  function getOpcServerAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildOpcServerAccessModel(access)
  }

  function getScriptAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildScriptAccessModel(access)
  }

  function getNotificationAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildNotificationAccessModel(access)
  }

  function getNotificationTemplateAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildNotificationTemplateAccessModel(access)
  }

  function getHistoryAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildHistoryAccessModel(access)
  }

  function getSettingsAccess(projectId = route.params.projectId) {
    const access = getProjectResAccess(projectId)
    return buildSettingsAccessModel(access)
  }

  return {
    profile: profileModel,
    isPasswordChangeRequired,
    loading: computed(() =>
      authApi.loading
      || authApi.processingLogout
      || authApi.processingRefresh
      || validationApi.loading
      || accountApi.processing
    ),
    init,
    destroy,
    login,
    logout,
    updateSession,
    updatePassword,
    editProfile,
    setNotifications,
    validateSession,
    hasSession,
    getAccessToken,
    getOrganizationId,
    getOrganizationProjectAccess,
    getUsersAccess,
    getUserGroupsAccess,
    getProjectAccess,
    getDashboardAccess,
    getTagAccess,
    getScaleAccess,
    getGatewayAccess,
    getMqttGatewayAccess,
    getOpcServerAccess,
    getScriptAccess,
    getNotificationAccess,
    getNotificationTemplateAccess,
    getHistoryAccess,
    getSettingsAccess
  }
})

export default useUser
