import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import wsClient, { projects } from '@/api/ws'
import { useNotifications } from '@/stores'

/**
 * @param {string} groupId
 * @param {SocketCallbacks} callbacks
 */
export default function (groupId, callbacks) {
  const { t: $t } = useI18n({ useScope: 'global' })
  const notificationsStore = useNotifications()

  const loading = ref(false)

  onMounted(() => {
    wsClient.addListener(groupId, callbacks)
  })

  onBeforeUnmount(() => {
    wsClient.removeListener(groupId)
  })

  async function listenProjects(projectIds) {
    const data = {}
    loading.value = true
    try {
      await projects.listenProjects(groupId)(projectIds)
      data.response = true
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.projects.ws.listenProjects.error'))
      console.error('projects listening request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function stopListenProjects() {
    const data = {}
    loading.value = true
    try {
      await projects.stopListenProjects(groupId)()
      data.response = true
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.projects.ws.stopListenProjects.error'))
      console.error('projects stop listening request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  return {
    loading,
    listenProjects,
    stopListenProjects
  }
}
