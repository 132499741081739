import { ref, computed, watch } from 'vue'

import { getScaleSegments } from '@/utils/gaugeHelpers'

export default function (settings, chart, alarmColor, warningColor, animationTime = 300) {
  const animationSettings = {
    time: null,
    request: null
  }
  let currentValuePercent = ref()

  const normalizedParams = computed(() => getScaleSegments({
    min: settings.min,
    max: settings.max,
    startAlarm: settings.startAlarm,
    endAlarm: settings.endAlarm,
    startWarning: settings.startWarning,
    endWarning: settings.endWarning
  }))

  const normalizedCurrent = computed(() => {
    const data = {
      absolute: settings.current >= settings.max
        ? normalizedParams.value.length
        : settings.current <= settings.min
          ? 0
          : Math.abs(settings.current - settings.min)
    }
    data.percent = data.absolute / normalizedParams.value.length
    return data
  })

  const animateValue = time => {
    if (!settings.animation
      || !settings.properties.showValue
      || !settings.properties.showBorders
      || currentValuePercent.value === null) {
      currentValuePercent.value = normalizedCurrent.value.percent
      animationSettings.startTime = 0
    } else {
      let elapsed = 1
      if (animationSettings.startTime) {
        elapsed = time - animationSettings.startTime
      } else {
        animationSettings.startTime = time
      }
      if (elapsed >= animationTime) {
        currentValuePercent.value = normalizedCurrent.value.percent
        animationSettings.startTime = 0
      } else {
        currentValuePercent.value = animationSettings.old + animationSettings.difference * (elapsed / animationTime)
      }
    }
    chart.value?.chart?.render()
  }

  const returnColorByValue = (val, normalVal = 'black') => {
    if (settings.properties?.showBorders) {
      if ((val <= normalizedParams.value.startAlarm && normalizedParams.value.startAlarmLength)
        || (val >= normalizedParams.value.endAlarm && normalizedParams.value.endAlarmLength)) {
        return alarmColor
      }
      if ((val <= normalizedParams.value.startWarning && normalizedParams.value.startWarningLength)
        || (val >= normalizedParams.value.endWarning && normalizedParams.value.endWarningLength)) {
        return warningColor
      }
    }

    return normalVal
  }

  const calcTextScales = (size, minStep) => {
    const maxScale = Math.floor(size / minStep)
    let textScales = 2
    for (let i = 3; i <= maxScale; i++) {
      if (normalizedParams.value.length % i === 0) {
        textScales = i
      }
    }
    return textScales
  }

  watch(
    () => normalizedCurrent.value.percent,
    n => {
      if (currentValuePercent.value === undefined) {
        currentValuePercent.value = n
        return
      }
      cancelAnimationFrame(animationSettings.request)
      const rect = settings.animation
        ? chart.value.$el.getBoundingClientRect()
        : false
      if (rect
        && (rect.top > -rect.height
          && rect.top < (window?.visualViewport?.height || document?.documentElement?.clientHeight))
        && settings.properties.showValue && settings.properties.showBorders) {
        if (n !== currentValuePercent.value) {
          animationSettings.old = currentValuePercent.value
          animationSettings.difference = n - currentValuePercent.value
          animationSettings.request = requestAnimationFrame(animateValue)
        }
      } else {
        currentValuePercent.value = n
        chart.value?.chart?.render?.()
      }
    },
    { immediate: true }
  )

  return {
    animationSettings,
    currentValuePercent,
    normalizedParams,
    normalizedCurrent,
    animateValue,
    returnColorByValue,
    calcTextScales
  }
}
