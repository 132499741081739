import { useI18n } from 'vue-i18n'
import axios, { CanceledError } from 'axios'
import { stringify } from 'qs'

import { API, APP } from '@/common/constants'
import { useUser, useNotifications } from '@/stores'
import { buildRequestHeaders, setAuthorizationHeader } from './utils'
import { cacheAdapter } from './cache'

const instance = axios.create({
  baseURL: `/api/${APP.API_VERSION}`,
  paramsSerializer: {
    serialize: params => stringify(params, { arrayFormat: 'comma' })
  },
  adapter: cacheAdapter(axios.getAdapter(axios.defaults.adapter))
})

instance.interceptors.request.use(config => ({
  ...config,
  headers: buildRequestHeaders(config.headers)
}))

instance.interceptors.response.use(response => (
  response.config.responseType === 'blob'
    ? {
      httpStatus: response.status,
      file: response.data
    }
    : {
      httpStatus: response.status,
      ...response.data?.data
    }
), async error => {
  if (error instanceof CanceledError) {
    throw { isAborted: true }
  }

  const httpStatus = error.response.status
  const responseCode = error.response.data.error?.code
  if (httpStatus === 401 && responseCode === 'EXPIRED') {
    const userStore = useUser()
    if (await userStore.updateSession()) {
      setAuthorizationHeader(error.config.headers, userStore.getAccessToken())
      return instance(error.config)
    } else {
      userStore.logout(true)
    }
  } else if (httpStatus === 403 && responseCode === 'ACCESS_IS_DENIED') {
    const { t: $t } = useI18n({ useScope: 'global' })
    const notifications = useNotifications()
    notifications.showNotification('error', $t('api.common.http.accessError'))
  } else if (
    httpStatus === 401 && responseCode === 'UNAUTHORIZED_REQUEST_ERROR'
    || httpStatus === 403 && responseCode === 'DEFAULT_PASSWORD'
  ) {
    const userStore = useUser()
    userStore.logout(true)
  } else if (
    httpStatus === 400
    && responseCode === API.COMMON_ERRORS.VALIDATION_FIELDS_ERROR
    && error.response.data.error.data.fieldErrors.organizationId === 'NOT_FOUND'
  ) {
    const userStore = useUser()
    userStore.logout()
  } else {
    throw {
      httpStatus,
      ...error.response.data.error
    }
  }
})

export default instance
