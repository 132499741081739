import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

import { Modal } from '@/utils/modalUtils'

const useModals = defineStore('modals', () => {
  const modals = ref([])

  const visibleModal = computed(() =>
    modals.value.length
      ? modals.value.at(-1)
      : null
  )

  function destroy() {
    modals.value = []
  }

  /**
   * @param options
   * @param {string} options.template
   * @param {Object=} options.config
   * @param {ModalCallbacks=} options.callbacks
   */
  function showModal({ template, config, callbacks }) {
    modals.value.push(new Modal(
      uuid(),
      template,
      config,
      callbacks
    ))
  }

  /**
   * @param {any=} data close callback return data
   * @param {boolean=} force close callback without navigation guard
   */
  async function closeModal(data, force) {
    let next = true
    if (!force) {
      const modal = modals.value.at(-1)
      if (modal) {
        next = await modal.close(data)
        if (next) {
          modals.value.pop()
        }
      }
    }

    return next
  }

  async function closeAllModals() {
    let success = true

    for (let i = 0; i < modals.value.length; i++) {
      if (success) {
        success = await closeModal(null, true)
      } else {
        success = false
        break
      }
    }

    return success
  }

  return {
    modals,
    visibleModal,
    destroy,
    showModal,
    closeModal,
    closeAllModals
  }
})

export default useModals
