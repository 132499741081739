
const STATUSES = {
  BAN: 'BAN',
  ACTIVE: 'ACTIVE'
}

const STATUS_TRANSLATES = {
  [STATUSES.BAN]: `domains.user.statuses.${STATUSES.BAN}`,
  [STATUSES.ACTIVE]: `domains.user.statuses.${STATUSES.ACTIVE}`
}

const STATUS_LIST = [
  {
    id: STATUSES.ACTIVE,
    title: STATUS_TRANSLATES[STATUSES.ACTIVE]
  }, {
    id: STATUSES.BAN,
    title: STATUS_TRANSLATES[STATUSES.BAN]
  }
]

export default {
  STATUSES,
  STATUS_TRANSLATES,
  STATUS_LIST
}
