const SORT_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC'
}

const PAGINATION_SIZES = {
  XS: 30,
  S: 60,
  M: 90
}

const COMMON_ERRORS = {
  VALIDATION_FIELDS_ERROR: 'VALIDATION_FIELDS_ERROR',
  UNAUTHORIZED_REQUEST_ERROR: 'UNAUTHORIZED_REQUEST_ERROR',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  EXPIRED: 'EXPIRED',
  DEFAULT_PASSWORD: 'DEFAULT_PASSWORD'
}

const WS_MSG_TYPES = {
  REPLY: 'REPLY',
  EVENT: 'EVENT'
}

const WS_MSG_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

export default {
  SORT_DIRECTION,
  PAGINATION_SIZES,
  COMMON_ERRORS,
  WS_MSG_TYPES,
  WS_MSG_STATUS
}
