import { v4 as uuid } from 'uuid'

import { useUser } from '@/stores'
import { getDeviceId } from '@/utils/session'

const setAuthorizationHeader = (headers, accessToken) => {
  headers.Authorization = `Bearer ${accessToken}`
  return headers
}

const buildRequestHeaders = requestHeaders => {
  const userStore = useUser()

  const headers = {
    'Accept-Language': process.env.DEFAULT_LANG,
    'X-Request-Id': uuid(),
    'X-Device-id': getDeviceId(),
    'X-Platform': 'web',
    ...requestHeaders
  }

  const accessToken = userStore.getAccessToken()
  if (accessToken) {
    setAuthorizationHeader(headers, accessToken)
  }

  return headers
}

const addValidationHeader = token => {
  return {
    'X-Password-Validation-Token': token
  }
}

export {
  setAuthorizationHeader,
  buildRequestHeaders,
  addValidationHeader
}
