import { API } from '@/common/constants'
import { useUser } from '@/stores'

const UNRECOVERABLE_CLOSE_CODE_LIST = [1008]
const RECOVERING_DELAY_TIMEOUT_MS = 3000
const RECOVERING_TRIES_LIMIT = 3

/**
 * @typedef SocketCallbacks
 * @type {Object}
 * @property {function} onMessage message handler
 * @property {function} onReconnect recovering connection handler
 */

class WS {
  #socketInstance = null
  #socketQueue = []
  #socketListenersMap = new Map()
  #socketPendingMap = new Map()
  #socketReconnectTries = 0
  #socketRecovering = null
  #socketReconnectId = null
  #socketCloseIntention = false

  get connected() {
    return this.#socketInstance?.readyState === 1
  }

  get exceededConnectionTries() {
    return this.#socketReconnectTries >= RECOVERING_TRIES_LIMIT
  }

  subscribe(token) {
    return new Promise((resolve, reject) => {
      if (this.connected) {
        console.error('There is opened socket connection')
        return reject()
      }

      if (!token) {
        console.error('Socket connection access restriction')
        return reject()
      }

      const protocol = location.protocol === 'https:'
        ? 'wss'
        : 'ws'
      this.#socketInstance = new WebSocket(`${protocol}:${location.host}/events/v1/ws?token=${token}`)

      this.#socketInstance.onopen = () => {
        this.#socketReconnectTries = 0
        this.#checkSocketMessageQueue()
        resolve()
      }

      this.#socketInstance.onerror = e => {
        console.error(e)
        this.#socketInstance.close()
        reject()
      }

      this.#socketInstance.onmessage = e => {
        let response
        try {
          response = JSON.parse(e.data)
        } catch (e) {
          console.error('error of handling socket\'s message')
        }
        if (response?.action) {
          this.#handleServerMessage(response)
        }
      }

      this.#socketInstance.onclose = async e => {
        if (this.#socketCloseIntention) {
          this.#socketCloseIntention = false
          clearTimeout(this.#socketReconnectId)
          return
        }

        if (this.#socketRecovering) return

        this.#socketRecovering = true
        const success = await this.reconnect(e.code)
        if (!success) {
          console.error('close socket', e)
        }
        this.#socketRecovering = false
      }
    })
  }

  unsubscribe() {
    if (this.connected) {
      this.#socketCloseIntention = true
      this.#socketInstance.close()
    }
  }

  async reconnect(code) {
    if (this.connected) {
      return true
    }

    const userStore = useUser()
    if (
      code && UNRECOVERABLE_CLOSE_CODE_LIST.includes(code)
      || this.exceededConnectionTries
      || !userStore.hasSession()
      || !userStore.validateSession() && !await userStore.updateSession()
    ) {
      userStore.logout(true)
    } else {
      const success = await this.#tryReconnect(userStore.getAccessToken())
      return success || this.reconnect()
    }
  }

  async #tryReconnect(token) {
    return new Promise(resolve => {
      this.#socketReconnectTries++
      this.#socketReconnectId = setTimeout(async () => {
        try {
          await this.subscribe(token)
          for (const cbs of this.#socketListenersMap.values()) {
            cbs.onReconnect()
          }
          resolve(true)
        } catch (e) {
          resolve(false)
          console.error('socket reconnection error', e)
        } finally {
          this.#socketReconnectId = null
        }
      }, RECOVERING_DELAY_TIMEOUT_MS * this.#socketReconnectTries ** 2)
    })
  }

  #checkSocketMessageQueue() {
    if (this.connected && this.#socketQueue.length) {
      this.#socketQueue.forEach(([groupId, msg, cb]) => {
        cb(this.sendMessage(groupId, msg))
      })
      this.#socketQueue = []
    }
  }

  #handleServerMessage(msg) {
    if (msg.type === API.WS_MSG_TYPES.REPLY && this.#socketPendingMap.has(msg.requestId)) {
      if (msg.status === API.WS_MSG_STATUS.SUCCESS) {
        this.#socketPendingMap.get(msg.requestId).resolve(msg.data)
      } else if (msg.status === API.WS_MSG_STATUS.ERROR) {
        this.#socketPendingMap.get(msg.requestId).reject(msg.error)
      }
    } else if (msg.type === API.WS_MSG_TYPES.EVENT) {
      for (const cbs of this.#socketListenersMap.values()) {
        cbs.onMessage(msg)
      }
    }
  }

  /**
   * Listen socket messages
   * @param {string} groupId listener identifier
   * @param {SocketCallbacks} callbacks
   */
  addListener(groupId, callbacks) {
    this.#socketListenersMap.set(groupId, {
      onMessage: callbacks?.onMessage || (() => {}),
      onReconnect: callbacks?.onReconnect || (() => {})
    })
  }

  /**
   * Remove subscription to messages and decline all queued messages
   * @param groupId listener identifier
   */
  removeListener(groupId) {
    this.#socketListenersMap.delete(groupId)
  }

  /**
   * Send message to socket. Message will be postponed if socket
   * isn't initialized yet. Message won't be sent if there is no
   * listener for this groupId
   * @param groupId listener identifier
   * @param msg socket message
   */
  sendMessage(groupId, msg) {
    if (!this.#socketListenersMap.has(groupId)) {
      return
    }

    return new Promise((resolve, reject) => {
      if (this.connected) {
        this.#socketInstance.send(JSON.stringify(msg))
        this.#socketPendingMap.set(msg.requestId, { resolve, reject })
      } else {
        this.#socketQueue.push([groupId, msg, resolve])
      }
    })
  }
}

const instance = new WS()

export default instance
