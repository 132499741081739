import { APP } from '@/common/constants'

const routes = [{
  path: '/auth',
  name: APP.PAGES.AUTH,
  component: () => import('@/views/auth')
}, {
  path: '/*',
  component: () => import('@/layout'),
  children: [{
    path: '/',
    name: APP.PAGES.MAIN,
    component: () => import('@/views/main')
  }, {
    path: '/profile',
    name: APP.PAGES.PROFILE,
    component: () => import('@/views/profile')
  }, {
    path: '/profile/edit',
    name: APP.PAGES.PROFILE_EDIT,
    component: () => import('@/views/profile/_edit')
  }, {
    path: '/users',
    name: APP.PAGES.USERS,
    component: () => import('@/views/users')
  }, {
    path: '/users/:userId',
    name: APP.PAGES.USER,
    component: () => import('@/views/users/_id')
  }, {
    path: '/user-groups/:groupId/users',
    name: APP.PAGES.USER_GROUP_USERS,
    component: () => import('@/views/users/group/_users')
  }, {
    path: '/user-groups/:groupId/projects',
    name: APP.PAGES.USER_GROUP_PROJECTS,
    component: () => import('@/views/users/group/_projects')
  }, {
    path: '/user-groups/:groupId/access',
    name: APP.PAGES.USER_GROUP_ACCESS,
    component: () => import('@/views/users/group/_access')
  }, {
    path: '/p/:projectId/components/tags/:tagType/:tagId',
    name: APP.PAGES.TAG,
    component: () => import('@/views/tag')
  }, {
    path: '/p/:projectId/notifications/templates/:templateType/:templateId',
    name: APP.PAGES.NOTIFICATION_TEMPLATE,
    component: () => import('@/views/notification-template')
  }, {
    path: '/p/:projectId',
    name: APP.PAGES.PROJECT,
    component: () => import('@/views/project'),
    redirect: { name: APP.PAGES.DASHBOARDS },
    children: [{
      path: 'dashboards/:dashboardId?',
      name: APP.PAGES.DASHBOARDS,
      component: () => import('@/views/project/_dashboards')
    }, {
      path: 'components',
      name: APP.PAGES.COMPONENTS,
      component: () => import('@/views/project/_components'),
      redirect: { name: APP.PAGES.TAGS },
      children: [{
        path: 'tags',
        name: APP.PAGES.TAGS,
        component: () => import('@/views/project/_components/_tags')
      }, {
        path: 'gateways',
        name: APP.PAGES.GATEWAYS,
        component: () => import('@/views/project/_components/_gateways')
      }, {
        path: 'mqtt-gateways',
        name: APP.PAGES.MQTT_GATEWAYS,
        component: () => import('@/views/project/_components/_mqtt-gateways')
      }, {
        path: 'opc-servers',
        name: APP.PAGES.OPC_SERVERS,
        component: () => import('@/views/project/_components/_opcua')
      }]
    }, {
      path: 'scripts/:scriptId?',
      name: APP.PAGES.SCRIPTS,
      component: () => import('@/views/project/_scripts')
    }, {
      path: 'notifications',
      name: APP.PAGES.NOTIFICATIONS,
      component: () => import('@/views/project/_notifications'),
      redirect: { name: APP.PAGES.NOTIFICATION_LIST },
      children: [{
        path: '',
        name: APP.PAGES.NOTIFICATION_LIST,
        component: () => import('@/views/project/_notifications/_notifications')
      }, {
        path: 'templates',
        name: APP.PAGES.NOTIFICATION_TEMPLATES,
        component: () => import('@/views/project/_notifications/_templates')
      }]
    }, {
      path: 'history',
      name: APP.PAGES.HISTORY,
      component: () => import('@/views/project/_history')
    }, {
      path: 'settings',
      name: APP.PAGES.SETTINGS,
      component: () => import('@/views/project/_settings')
    }]
  }, {
    path: '/error/:type?',
    name: APP.PAGES.ERROR,
    component: () => import('@/views/error')
  }]
}]

export default routes
