<script>
import { provide } from 'vue'
import { MODAL } from '@/common/constants'

export default {
  name: 'AppConfigProvider',
  props: {
    /**
     * @type Modal
     */
    modal: Object
  },
  setup(props, { slots }) {
    provide(MODAL.CONTEXT_PROVIDER_KEY, props.modal)

    return () => slots.default()
  }
}
</script>
