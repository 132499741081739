import { ref } from 'vue'

import { projects } from '@/api'
import { useNotifications, useUser } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const userStore = useUser()
  const notificationsStore = useNotifications()

  const loading = ref()
  const processing = ref()

  async function getProjects() {
    loading.value = true
    const data = {}
    try {
      const response = await projects.getProjects(userStore.getOrganizationId())
      data.response = response.projects
    } catch (e) {
      data.error = e
      console.error('projects loading error')
      notificationsStore.showNotification('error', $t('api.projects.http.getProjects.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function getProjectDetail(projectId) {
    processing.value = true
    const data = {}
    try {
      const response = await projects.getProjectDetail(projectId)
      data.response = response.project
    } catch (e) {
      data.error = e
      console.error('projects loading error')
      notificationsStore.showNotification('error', $t('api.projects.http.getProjectDetail.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function addProject({ title, description, address }) {
    const data = {}
    processing.value = true
    try {
      const response = await projects.addProject(userStore.getOrganizationId(), {
        title,
        description,
        address
      })
      data.response = response.project
      notificationsStore.showNotification('success', $t('api.projects.http.addProject.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.projects.http.addProject.error'))
      console.error('error of dashboard adding', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function editProject(projectId, form) {
    const data = {}
    processing.value = true
    try {
      const response = await projects.editProject(projectId, form)
      data.response = response.project
      notificationsStore.showNotification('success', $t('api.projects.http.editProject.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.projects.http.editProject.error'))
      console.error('error of dashboard adding', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteProject(projectId) {
    const data = {}
    processing.value = true
    try {
      await projects.deleteProject(projectId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.projects.http.deleteProject.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.projects.http.deleteProject.error'))
      console.error('error of dashboard adding', e)
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getProjects,
    getProjectDetail,
    addProject,
    editProject,
    deleteProject
  }
}
