import { computed } from 'vue'
import { useFormErrors } from 'vee-validate'

export default function(fieldPath) {
  const errors = useFormErrors()
  return computed(() => {
    if (!errors) return {}

    if (fieldPath) {
      return Object.keys(errors.value).reduce((acc, el) => {
        const startPoint = el.indexOf(fieldPath)
        const endPoint = el.indexOf('.', startPoint)
        if (~startPoint) {
          const key = el.slice(0, endPoint)
          if (acc[key]) {
            acc[key].push(el.slice(endPoint))
          } else {
            acc[key] = [el.slice(endPoint)]
          }
        }
        return acc
      }, {})
    }

    return errors
  })
}
