import http from '../instance'

const getScriptsCatalog = ({ projectId }) => {
  return http.get('/script-nodes', { params: { projectId } })
}

const addScriptNode = ({ title, projectId, scriptNodeId }) => {
  return http.post('/script-nodes', { title, projectId, scriptNodeId })
}

const renameScriptFolder = ({ id, title }) => {
  return http.post(`/script-nodes/${id}/edit`, { title })
}

const deleteScriptFolder = ({ folderId, safeChildren }) => {
  return http.post(`/script-nodes/${folderId}/delete`, { safeChildren })
}

const moveInScriptFolder = ({ scriptNodeId, scriptIds, macroIds }) => {
  return http.post(`/script-nodes/${scriptNodeId}/move-into`, { scriptIds, macroIds })
}

export default {
  getScriptsCatalog,
  addScriptNode,
  renameScriptFolder,
  deleteScriptFolder,
  moveInScriptFolder
}
