// eslint-disable-next-line no-unused-vars
import { FormContext, FormOptions, FormSchema, GenericObject, useForm } from 'vee-validate'

/**
 * @param {FormOptions<GenericObject, GenericObject, FormSchema<GenericObject>>} opts
 * @returns {FormContext<GenericObject, GenericObject>}
 */
export default function (opts = {}) {
  return useForm({
    initialValues: opts.initialValues || null,
    validationSchema: opts.validationSchema || null,
    keepValuesOnUnmount: opts.keepValuesOnUnmount || false,
    validateOnMount: opts.validateOnMount || false
  })
}
