import { onBeforeRouteLeave } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useModals } from '@/stores'
import { useModalCallbacks } from '@/composables/utils'

/**
 * Add confirm modal before route leave
 * with condition for opening this modal
 * @param {() => boolean} isValid function should return "false" - if confirm should be shown
 * @param {Object=} options
 * @param {boolean=} options.bindToModal Show confirm modal in case of closing modal as well
 * @param {string=} options.title Confirm title
 * @param {string=} options.confirmLabel Confirm submit label
 */
export default function (isValid, {
  bindToModal,
  title,
  confirmLabel
} = {}) {
  const { t: $t } = useI18n({ useScope: 'global' })
  const modalStore = useModals()

  if (bindToModal) {
    useModalCallbacks({
      onBeforeClose: () => {
        return showConfirm()
      }
    })
  }

  onBeforeRouteLeave(() => showConfirm())

  function showConfirm() {
    if (isValid()) return true
    return new Promise(r => {
      modalStore.showModal({
        template: 'AppConfirmModal',
        config: {
          title: title || $t('domains.utils.useConfirmBeforeLeave.confirm.title'),
          confirmLabel: confirmLabel || $t('domains.utils.useConfirmBeforeLeave.confirm.label')
        },
        callbacks: {
          onClose: data => r(!!data)
        }
      })
    })
  }
}
