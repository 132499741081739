/**
 * @typedef ModalCallbacks
 * @property {Object} callbacks
 * @property {(data: any) => void} callbacks.onClose
 * @property {(data: any) => void} callbacks.onBeforeClose
 */

class Modal {
  id
  template
  config
  /** @type {ModalCallbacks} */
  innerCallbacks
  /** @type {ModalCallbacks} */
  outerCallbacks

  /**
   * @param {string} id
   * @param {string} template
   * @param {any} config
   * @param {ModalCallbacks} outerCallbacks
   */
  constructor(
    id,
    template,
    config,
    outerCallbacks
  ) {
    this.id = id
    this.template = template
    this.config = config
    this.innerCallbacks = {}
    this.outerCallbacks = outerCallbacks
  }

  /**
   * @param {ModalCallbacks} callbacks
   */
  setCallbacks(callbacks) {
    if (typeof callbacks.onBeforeClose === 'function') {
      this.innerCallbacks.onBeforeClose = callbacks.onBeforeClose
    }
  }

  async close(data) {
    let next = true
    if (this.innerCallbacks.onBeforeClose) {
      next = await this.innerCallbacks.onBeforeClose(data)
    }

    if (next) {
      if (this.outerCallbacks?.onClose) {
        this.outerCallbacks.onClose(data)
      }
    }

    return next
  }
}

export {
  Modal
}
