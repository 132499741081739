import { ref } from 'vue'
import { useRoute } from 'vue-router'

import { scales } from '@/api'
import { useNotifications } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const processing = ref(false)

  async function getScales() {
    const data = {}
    loading.value = true
    try {
      const response = await scales.getScales($route.params.projectId, {
        page: 0,
        size: 100
      })
      data.response = response.scales
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.scales.http.getScales.error'))
      console.error('scale\'s list request error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function addScale({ name, min, max, unit }) {
    const data = {}
    processing.value = true
    try {
      const response = await scales.addScale({
        projectId: $route.params.projectId,
        name: name,
        min: min,
        max: max,
        unit: unit
      })
      data.response = response.scales
      notificationsStore.showNotification('success', $t('api.scales.http.addScale.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.scales.http.addScale.error'))
      console.error('scale\'s adding item request error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function editScale({ id, name, min, max, unit }) {
    const data = {}
    processing.value = true
    try {
      const response = await scales.editScale({
        projectId: $route.params.projectId,
        scaleId: id,
        name,
        min,
        max,
        unit
      })
      data.response = response.scales
      notificationsStore.showNotification('success', $t('api.scales.http.editScale.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.scales.http.editScale.error'))
      console.error('scale\'s editing item request error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteScale(scaleId, forceDelete) {
    const data = {}
    processing.value = true
    try {
      await scales.deleteScale({
        scaleId,
        forceDelete
      })
      data.response = true
      notificationsStore.showNotification('success', $t('api.scales.http.deleteScale.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.scales.http.deleteScale.error'))
      console.error('scale\'s deleting item request error')
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getScales,
    addScale,
    editScale,
    deleteScale
  }
}
