import { ref } from 'vue'

import { notifications } from '@/api'
import { useNotifications } from '@/stores'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref()
  const loadingTemplates = ref()
  const loadingExport = ref()
  const processing = ref()
  let notificationsController = new AbortController()

  async function getNotifications(params, pagination) {
    if (loading.value) {
      cancelNotificationsRequests()
    }
    let isAborted
    const data = {}
    loading.value = true
    try {
      const response = await notifications.getNotifications(notificationsController.signal)({
        projectId: $route.params.projectId,
        q: params.q,
        type: params.type,
        severity: params.severity,
        state: params.state,
        templateIds: params.templateIds,
        sentAt: params.sentAt,
        sentFrom: params.sentFrom,
        sentTo: params.sentTo,
        startAt: params.startAt,
        startFrom: params.startFrom,
        startTo: params.startTo,
        sort: params.sort,
        sortDirection: params.sortDirection,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response.notifications
      data.total = response.total
    } catch (e) {
      if (!e.isAborted) {
        data.error = e
        notificationsStore.showNotification('error', $t('api.notifications.http.getNotifications.error'))
        console.error('notifications request error', e)
      } else {
        isAborted = true
      }
    } finally {
      if (!isAborted) {
        loading.value = false
      }
    }

    return data
  }

  async function getNotificationInfo(notificationId) {
    const data = {}
    loading.value = true
    try {
      const response = await notifications.getNotificationInfo(notificationId)
      data.response = response.notification
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notifications.http.getNotificationInfo.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function getNotificationsBySource(params, pagination) {
    const data = {}
    loading.value = true
    try {
      const response = await notifications.getNotificationsBySource({
        triggerType: params.triggerType,
        triggerTypeId: params.triggerTypeId,
        projectId: $route.params.projectId,
        q: params.q,
        type: params.type,
        severity: params.severity,
        state: params.state,
        templateIds: params.templateIds,
        sentAt: params.sentAt,
        sentFrom: params.sentFrom,
        sentTo: params.sentTo,
        startAt: params.startAt,
        startFrom: params.startFrom,
        startTo: params.startTo,
        sort: params.sort,
        sortDirection: params.sortDirection,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response
      data.total = response.total
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notifications.http.getNotificationsBySource.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function getNotificationTemplates(pagination) {
    const data = {}
    loadingTemplates.value = true
    try {
      const response = await notifications.getNotificationTemplates({
        projectId: $route.params.projectId,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response.notificationTemplates
      data.total = response.total
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notifications.http.getNotificationTemplates.error'))
    } finally {
      loadingTemplates.value = false
    }

    return data
  }

  async function changeNotificationStatus(params) {
    const data = {}
    processing.value = true
    try {
      await notifications.changeNotificationStatus({
        projectId: $route.params.projectId,
        state: params.state,
        notificationIds: params.notificationIds
      })
      data.response = true
      notificationsStore.showNotification('success', $t('api.notifications.http.changeNotificationStatus.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notifications.http.changeNotificationStatus.error'))
      console.error('status create request error', e)
    } finally {
      processing.value = false
    }

    return data
  }

  async function exportNotifications(params) {
    const data = {}
    loadingExport.value = true
    try {
      const response = await notifications.exportNotifications({
        projectId: $route.params.projectId,
        q: params.q,
        type: params.type,
        severity: params.severity,
        state: params.state,
        templateIds: params.templateIds,
        sentAt: params.sentAt,
        sentFrom: params.sentFrom,
        sentTo: params.sentTo,
        startAt: params.startAt,
        startFrom: params.startFrom,
        startTo: params.startTo,
        sort: params.sort,
        sortDirection: params.sortDirection,
        sourceType: params.sourceType,
        sourceId: params.sourceId
      })
      data.response = response.link
      notificationsStore.showNotification('success', $t('api.notifications.http.exportNotifications.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notifications.http.exportNotifications.error'))
      console.error('notifications export link request error', e)
    } finally {
      loadingExport.value = false
    }

    return data
  }

  function cancelNotificationsRequests() {
    notificationsController.abort()
    notificationsController = new AbortController()
  }

  return {
    loading,
    loadingTemplates,
    loadingExport,
    processing,
    getNotifications,
    getNotificationsBySource,
    getNotificationInfo,
    getNotificationTemplates,
    changeNotificationStatus,
    exportNotifications
  }
}
