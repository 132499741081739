import http from '../instance'

const getUserGroups = organizationId =>
  http.get('/user-groups', { params: { organizationId } })

const getUserGroup = groupId => http.get(`/user-groups/${groupId}`)

const createUserGroup = (organizationId, { title }) =>
  http.post('/user-groups', {
    organizationId,
    title
  })

const editUserGroup = (groupId, { title }) =>
  http.post(`/user-groups/${groupId}/edit`, {
    title
  })

const editUserGroupUsers = (groupId, { users }) =>
  http.post(`/user-groups/${groupId}/users/edit`, {
    users
  })

const editUserGroupProjects = (groupId, { projects, projectsAddNew }) =>
  http.post(`/user-groups/${groupId}/projects/edit`, {
    projects,
    projectsAddNew
  })

const editUserGroupAccess = (groupId, { access }) =>
  http.post(`/user-groups/${groupId}/access/edit`, {
    access
  })

const deleteUserGroup = groupId => http.post(`/user-groups/${groupId}/delete`)

export default {
  getUserGroups,
  getUserGroup,
  createUserGroup,
  editUserGroup,
  editUserGroupUsers,
  editUserGroupProjects,
  editUserGroupAccess,
  deleteUserGroup
}
