export default function () {
  const el = document.createElement('div')
  el.classList.add('dummy-scroll')
  document.body.appendChild(el)
  const scrollWidth = el.offsetWidth - el.clientWidth
  document.body.removeChild(el)
  if (scrollWidth) {
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      `${scrollWidth}px`
    )
  }
}
