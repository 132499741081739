import http from '../instance'

const getScales = (projectId, { page, size }) =>
  http.get('/scales', { params: { projectId, page, size } })

const editScale = ({ projectId, scaleId, name, min, max, unit }) =>
  http.post(`/scales/${scaleId}/edit`, {
    projectId,
    name,
    min,
    max,
    unit
  })

const addScale = ({ projectId, name, min, max, unit }) =>
  http.post('/scales', {
    projectId,
    name,
    min,
    max,
    unit
  })

const deleteScale = ({ scaleId, forceDelete }) =>
  http.post(`/scales/${scaleId}/delete`, { forceDelete })

export default {
  getScales,
  editScale,
  addScale,
  deleteScale
}
