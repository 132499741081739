const RESOURCES = {
  PROJECT: 'project',
  USER: 'user',
  USER_GROUP: 'userGroup',
  SETTINGS: 'settings',
  SCALE: 'scale',
  DASHBOARD: 'dashboard',
  SCRIPT: 'script',
  HISTORY: 'history',
  COMPONENTS: 'components',
  TAG: 'tag',
  TAG_TEMPLATE: 'tagTemplate',
  GATEWAY: 'gateway',
  MQTT_GATEWAY: 'mqttGateway',
  OPC_SERVER: 'opcServer',
  NOTIFICATION: 'notification',
  NOTIFICATION_LIST: 'notificationList',
  NOTIFICATION_TEMPLATE: 'notificationTemplate',
  NOTIFICATION_TEMPLATE_USER: 'notificationTemplateUser',
  NOTIFICATION_TEMPLATE_SYSTEM: 'notificationTemplateSystem'
}

const ACTIONS = {
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  INTERACTION: 'interaction',
  IMPORT: 'import',
  EXPORT: 'export',
  SET_ACTIVE: 'setActive'
}

export default {
  RESOURCES,
  ACTIONS
}
