import http from '../instance'

const getGateways = projectId =>
  http.get('/mqtt-gateways', { params: { projectId } })

const addGateway = ({ projectId, name }) =>
  http.post('/mqtt-gateways', { projectId, name })

const editGateway = ({ mqttGatewayId, name }) =>
  http.post(`/mqtt-gateways/${mqttGatewayId}/edit`, { name })

const deleteGateway = mqttGatewayId =>
  http.post(`/mqtt-gateways/${mqttGatewayId}/delete`)

const getGatewaysClient = projectId =>
  http.get('/mqtt-gateways/client', { params: { projectId } })

const addGatewaysClient = ({ projectId, userName, password }) =>
  http.post('/mqtt-gateways/client', { projectId, userName, password })

const editGatewaysClient = ({ projectId, userName, password }) =>
  http.post('/mqtt-gateways/client/edit', { projectId, userName, password })

export default {
  getGateways,
  addGateway,
  editGateway,
  deleteGateway,
  getGatewaysClient,
  addGatewaysClient,
  editGatewaysClient
}
