import instance from '@/localization'

async function loadLocale(locale) {
  const messages = (await import(`@/localization/${locale}`))?.default
  if (messages) instance.global.mergeLocaleMessage(locale, messages)
}

export {
  loadLocale
}
