import { ref } from 'vue'

import { auth } from '@/api'

export default function () {
  const loading = ref()
  const processingRefresh = ref()
  const processingLogout = ref()

  async function login(login, password) {
    const data = {}
    loading.value = true
    try {
      data.response = await auth.login({ login, password })
    } catch (e) {
      data.error = e
      console.error(e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function logout() {
    const data = {}
    processingLogout.value = true
    try {
      await auth.logout()
      data.response = true
    } catch (e) {
      data.error = e
      console.error('logout error', e)
    } finally {
      processingLogout.value = false
    }

    return data
  }

  async function refresh(refreshToken) {
    const data = {}
    processingRefresh.value = true
    try {
      const response = await auth.refreshToken(refreshToken)
      data.response = response.session
    } catch (e) {
      data.error = e
      console.error(e)
    } finally {
      processingRefresh.value = false
    }

    return data
  }

  return {
    loading,
    processingRefresh,
    processingLogout,
    login,
    logout,
    refresh
  }
}
