import { createI18n } from 'vue-i18n'
import moment from 'moment'

import * as pluralRules from '@/utils/localizationPluralization'
import { loadLocale } from '@/utils/localizationHelpers'

const LOCALE = process.env.DEFAULT_LANG

const instance = createI18n({
  legacy: false,
  locale: LOCALE,
  pluralRules,
  missingWarn: false
})
moment.locale(LOCALE)

loadLocale(LOCALE)

export default instance
