import http from '../instance'

const getProjects = organizationId => http.get('/projects', { params: { organizationId } })

const getProjectDetail = projectId => http.get(`/projects/${projectId}`)

const addProject = (organizationId, {
  title,
  description,
  address
}) => http.post('/projects', { organizationId, title, description, address })

const editProject = (projectId, {
  title,
  description,
  defaultRate,
  defaultOfflineTimeout,
  defaultStorageTimeLimit,
  defaultOffsetValue,
  address
}) =>
  http.post(`/projects/${projectId}/edit`, {
    title,
    description,
    defaultRate,
    defaultOfflineTimeout,
    defaultStorageTimeLimit,
    defaultOffsetValue,
    address
  })

const deleteProject = projectId => http.post(`/projects/${projectId}/delete`)

export default {
  getProjects,
  getProjectDetail,
  addProject,
  editProject,
  deleteProject
}
