import { ref } from 'vue'

import { notificationTemplates } from '@/api'
import { useNotifications } from '@/stores'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loadingNotificationTemplates = ref()
  const processingNotificationTemplates = ref()
  let notificationTemplatesController = new AbortController()

  async function getNotificationTemplates(params, pagination) {
    if (loadingNotificationTemplates.value) {
      cancelNotificationTemplatesRequests()
    }
    let isAborted
    const data = {}
    loadingNotificationTemplates.value = true
    try {
      const response = await notificationTemplates.getNotificationTemplates(notificationTemplatesController.signal)({
        projectId: $route.params.projectId,
        q: params.q,
        type: params.type,
        severity: params.severity,
        targets: params.targets,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response.notificationTemplates
      data.total = response.total
    } catch (e) {
      if (!e.isAborted) {
        data.error = e
        notificationsStore.showNotification('error', $t('api.notificationTemplates.http.getNotificationTemplates.error'))
        console.error('notification templates request error', e)
      } else {
        isAborted = true
      }
    } finally {
      if (!isAborted) {
        loadingNotificationTemplates.value = false
      }
    }
    return data
  }

  async function getNotificationTemplateInfo(notificationTemplateId) {
    const data = {}
    loadingNotificationTemplates.value = true
    try {
      const response = await notificationTemplates.getNotificationTemplateInfo(notificationTemplateId)
      data.response = response.notificationTemplate
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notificationTemplates.http.getNotificationTemplateInfo.error'))
    } finally {
      loadingNotificationTemplates.value = false
    }
    return data
  }

  async function createNotificationTemplate(form) {
    const data = {}
    processingNotificationTemplates.value = true
    try {
      data.response = await notificationTemplates.createNotificationTemplate(form)
      notificationsStore.showNotification('success', $t('api.notificationTemplates.http.createNotificationTemplate.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notificationTemplates.http.createNotificationTemplate.error'))
      console.error('notification template create request error', e)
    } finally {
      processingNotificationTemplates.value = false
    }
    return data
  }

  async function editNotificationTemplate(templateId, form) {
    const data = {}
    processingNotificationTemplates.value = true
    try {
      const response = await notificationTemplates.editNotificationTemplate(templateId, form)
      data.response = response.notificationTemplate
      notificationsStore.showNotification('success', $t('api.notificationTemplates.http.editNotificationTemplate.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notificationTemplates.http.editNotificationTemplate.error'))
      console.error('notification template edit request error', e)
    } finally {
      processingNotificationTemplates.value = false
    }

    return data
  }

  async function deleteNotificationTemplate(notificationTemplateId) {
    const data = {}
    loadingNotificationTemplates.value = true
    try {
      await notificationTemplates.deleteNotificationTemplate(notificationTemplateId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.notificationTemplates.http.deleteNotificationTemplate.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.notificationTemplates.http.deleteNotificationTemplate.error'))
      console.error('notification template delete request error', e)
    } finally {
      loadingNotificationTemplates.value = false
    }

    return data
  }

  function cancelNotificationTemplatesRequests() {
    notificationTemplatesController.abort()
    notificationTemplatesController = new AbortController()
  }

  return {
    loadingNotificationTemplates,
    processingNotificationTemplates,
    getNotificationTemplates,
    getNotificationTemplateInfo,
    createNotificationTemplate,
    editNotificationTemplate,
    deleteNotificationTemplate
  }
}
