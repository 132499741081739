import { readonly, ref } from 'vue'
import { defineStore } from 'pinia'

const TYPES = ['success', 'error']

const MSG_TIMEOUT = 5000

const useNotifications = defineStore('notifications', () => {
  const notifications = ref([])

  function destroy() {
    notifications.value = []
  }

  function showNotification(type, msg, options, callback) {
    if (!TYPES.includes(type)) {
      return false
    }

    const id = notifications.value.length
      ? notifications.value.at(-1).id + 1
      : 0
    notifications.value.push({
      id,
      type,
      msg,
      callback
    })
    setTimeout(() => {
      closeNotification(id)
    }, MSG_TIMEOUT)

    return id
  }

  function closeNotification(id) {
    const index = notifications.value.findIndex(el => el.id === id)
    if (index || index === 0) {
      notifications.value.splice(index, 1)
    }
  }

  return {
    notifications: readonly(notifications),
    destroy,
    showNotification,
    closeNotification
  }
})

export default useNotifications
