import { ref, shallowRef } from 'vue'
import { defineStore } from 'pinia'

import { APP } from '@/common/constants'
import { getWidgetColors } from '@/utils/themeHelpers'

const useTheme = defineStore('theme', () => {
  const currentTheme = ref(null)
  const widgetColors = shallowRef({})

  function init() {
    switchTheme(APP.THEMES.LIGHT)
  }

  function destroy() {
    currentTheme.value = null
    widgetColors.value = {}
  }

  function switchTheme(theme) {
    currentTheme.value = theme
    widgetColors.value = getWidgetColors()
  }

  return {
    currentTheme,
    widgetColors,
    init,
    destroy
  }
})

export default useTheme
