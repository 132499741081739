import { ref } from 'vue'

import { users } from '@/api'
import { useUser, useNotifications } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const userStore = useUser()
  const notificationsStore = useNotifications()

  const loading = ref()
  const processing = ref()

  async function getUsers(params, pagination) {
    const data = {}
    loading.value = true
    try {
      const response = await users.getUsers(userStore.getOrganizationId(), {
        q: params.q,
        groupId: params.groupId,
        status: params.status,
        sort: params.sort,
        sortDirection: params.sortDirection,
        page: pagination.page,
        size: pagination.size
      })
      data.response = response.users
      data.total = response.total
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.getUsers.error'))
      console.error('users loading error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function getUser(userId) {
    const data = {}
    loading.value = true
    try {
      const response = await users.getUser(userId)
      data.response = response.user
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.getUser.error'))
      console.error('users loading error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function createUser({
    firstName,
    middleName,
    lastName,
    email,
    notificationsInfo,
    groups
  }) {
    const data = {}
    loading.value = true
    try {
      const response = await users.createUser(userStore.getOrganizationId(), {
        firstName,
        middleName,
        lastName,
        email,
        notificationsInfo,
        groups
      })
      data.response = response.user
      notificationsStore.showNotification('success', $t('api.users.http.createUser.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.createUser.error'))
      console.error('users loading error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function editUser(userId, {
    firstName,
    middleName,
    lastName,
    email,
    notificationsInfo,
    groups
  }) {
    const data = {}
    loading.value = true
    try {
      const response = await users.editUser(userId, {
        firstName,
        middleName,
        lastName,
        email,
        notificationsInfo,
        groups
      })
      data.response = response.user
      notificationsStore.showNotification('success', $t('api.users.http.editUser.success'))
    } catch (e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.editUser.error'))
      console.error('users loading error')
    } finally {
      loading.value = false
    }

    return data
  }

  async function banUser(userId) {
    const data = {}
    processing.value = true
    try {
      const response = await users.banUser(userId)
      data.response = response.user
      notificationsStore.showNotification('success', $t('api.users.http.banUser.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.banUser.error'))
      console.error('user ban error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function unbanUser(userId) {
    const data = {}
    processing.value = false
    try {
      const response = await users.unbanUser(userId)
      data.response = response.user
      notificationsStore.showNotification('success', $t('api.users.http.unbanUser.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.unbanUser.error'))
      console.error('user unban error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function resetPassword(userId) {
    const data = {}
    processing.value = true
    try {
      await users.changeUserPassword(userId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.users.http.changeUserPassword.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.changeUserPassword.error'))
      console.error('user reset password error')
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteUser(userId) {
    const data = {}
    processing.value = true
    try {
      await users.deleteUser(userId)
      data.response = true
      notificationsStore.showNotification('success', $t('api.users.http.deleteUser.success'))
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.users.http.deleteUser.error'))
      console.error('user reset password error')
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getUsers,
    getUser,
    createUser,
    editUser,
    banUser,
    unbanUser,
    resetPassword,
    deleteUser
  }
}
