import { onBeforeUnmount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

import wsClient from '@/api/ws/instance'
import { useUser, useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const notificationsStore = useNotifications()
  const userStore = useUser()

  wsClient
    .subscribe(userStore.getAccessToken())
    .catch(() => {
      notificationsStore.showNotification('error', $t('domains.utils.useSocket.connectionError'))
    })

  onMounted(() => {
    window.addEventListener('focus', reconnect)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('focus', reconnect)
    wsClient.unsubscribe()
  })

  function reconnect() {
    wsClient.reconnect()
  }
}
