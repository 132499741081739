import http from '../instance'

const getTelegramLink = () =>
  http.post('/notification-settings/telegram/link')

const deleteTelegram = () =>
  http.post('/notification-settings/telegram/delete')

const changeNotificationsEmail = (email, enabled) =>
  http.post('/notification-settings/email/edit', {
    email,
    enabled
  })

const changeNotificationsPush = (type, enabled) =>
  http.post('/notification-settings/push/edit', {
    type,
    enabled
  })

const changeNotificationsTimezone = timezone =>
  http.post('/notification-settings/timezone/edit', {
    timezone
  })


export default {
  getTelegramLink,
  deleteTelegram,
  changeNotificationsEmail,
  changeNotificationsPush,
  changeNotificationsTimezone
}
