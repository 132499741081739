import { ref } from 'vue'
import { geocoding } from '@/api'
import { useNotifications } from '@/stores'
import { useI18n } from 'vue-i18n'

export default function() {
  const { t: $t } = useI18n({ useScope: 'global' })
  const notificationsStore = useNotifications()

  const loadingGeocoding = ref(false)

  async function forwardGeocoding(address) {
    const data = {}
    loadingGeocoding.value = true
    try {
      const response = await geocoding.forwardGeocoding(address)
      data.response = response.suggestions
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.geocoding.http.forwardGeocoding.error'))
      console.error('coordinates request error')
    } finally {
      loadingGeocoding.value = false
    }

    return data
  }

  async function reverseGeocoding(address) {
    const data = {}
    loadingGeocoding.value = true
    try {
      const response = await geocoding.reverseGeocoding(address)
      data.response = response.suggestions
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.geocoding.http.reverseGeocoding.error'))
      console.error('address request error')
    } finally {
      loadingGeocoding.value = false
    }

    return data
  }

  return {
    loadingGeocoding,
    forwardGeocoding,
    reverseGeocoding
  }
}
