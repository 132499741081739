import http from '../instance'

const forwardGeocoding = address =>
  http.get('geocoding/forward', { params: { q: address } })


const reverseGeocoding = coordinates =>
  http.get('geocoding/reverse', { params: coordinates })

export default {
  forwardGeocoding,
  reverseGeocoding
}
