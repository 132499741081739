const SECURITY_MODE = {
  NONE: 'NONE',
  SIGN: 'SIGN',
  SIGN_AND_ENCRYPT: 'SIGN_AND_ENCRYPT'
}

const SECURITY_MODE_LIST = [
  { id: SECURITY_MODE.SIGN, title: 'Sign' },
  { id: SECURITY_MODE.SIGN_AND_ENCRYPT, title: 'Sign and encrypt' },
  { id: SECURITY_MODE.NONE, title: 'None' }
]

const SECURITY_POLICY = {
  NONE: 'NONE',
  BASIC_256_SHA_256: 'BASIC_256_SHA_256',
  AES128_SHA256_RSA_OAEP: 'AES128_SHA256_RSA_OAEP',
  AES128_SHA256_RSA_PSS: 'AES128_SHA256_RSA_PSS'
}

const SECURITY_POLICY_LIST = [
  { id: SECURITY_POLICY.AES128_SHA256_RSA_OAEP, title: 'Aes128Sha256RsaOaep' },
  { id: SECURITY_POLICY.AES128_SHA256_RSA_PSS, title: 'Aes128Sha256RsaPss' },
  { id: SECURITY_POLICY.BASIC_256_SHA_256, title: 'Basic256Sha256' },
  { id: SECURITY_POLICY.NONE, title: 'None' }
]

const AUTH_TYPE = {
  USER: 'USER',
  ANONYMOUS: 'ANONYMOUS'
}

const AUTH_TYPE_LIST = [
  { id: AUTH_TYPE.USER, title: `domains.opcua.authType.${AUTH_TYPE.USER}` },
  { id: AUTH_TYPE.ANONYMOUS, title: `domains.opcua.authType.${AUTH_TYPE.ANONYMOUS}` }
]

const STATES = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE'
}
export default {
  SECURITY_MODE,
  SECURITY_MODE_LIST,
  SECURITY_POLICY,
  SECURITY_POLICY_LIST,
  AUTH_TYPE,
  AUTH_TYPE_LIST,
  STATES
}
