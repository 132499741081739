import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { access } from '@/api'
import { useUser, useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const userStore = useUser()
  const notificationsStore = useNotifications()

  const loading = ref()
  const loadingGroups = ref()

  async function getProjectAccess(projectId) {
    const data = {}
    loading.value = true
    try {
      const response = await access.getProjectAccess(projectId)
      data.response = response.access
    } catch (e) {
      data.error = e
      console.error('project access download error', e)
      notificationsStore.showNotification('error', $t('api.access.http.getProjectAccess.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function getAccessGroups() {
    const data = {}
    loadingGroups.value = true
    try {
      const response = await access.getAccessGroups(userStore.getOrganizationId())
      data.response = response.groups
    } catch (e) {
      data.error = e
      console.error('access groups loading error', e)
      notificationsStore.showNotification('error', $t('api.http.getAccessGroups.error'))
    } finally {
      loadingGroups.value = false
    }

    return data
  }

  return {
    loading,
    loadingGroups,
    getProjectAccess,
    getAccessGroups
  }
}
