import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import wsClient, { tags } from '@/api/ws'
import { useNotifications } from '@/stores'

/**
 * @param {string} groupId
 * @param {SocketCallbacks} callbacks
 */
export default function (groupId, callbacks) {
  const { t: $t } = useI18n({ useScope: 'global' })
  const notificationsStore = useNotifications()

  const loading = ref(false)

  onMounted(() => {
    wsClient.addListener(groupId, callbacks)
  })

  onBeforeUnmount(() => {
    wsClient.removeListener(groupId)
  })

  async function listenTags(tagIds) {
    const data = {}
    loading.value = true
    try {
      await tags.listenTags(groupId)(tagIds)
      data.response = true
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tags.ws.listenTags.error'))
      console.error('tag listening request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function stopListenTags() {
    const data = {}
    loading.value = true
    try {
      await tags.stopListenTags(groupId)()
      data.response = true
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.tags.ws.stopListenTags.error'))
      console.error('tag stop listening request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  return {
    loading,
    listenTags,
    stopListenTags
  }
}
