import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import wsClient, { scripts } from '@/api/ws'
import { useNotifications } from '@/stores'

/**
 * @param {string} groupId
 * @param {SocketCallbacks} callbacks
 */
export default function (groupId, callbacks) {
  const { t: $t } = useI18n({ useScope: 'global' })
  const notificationsStore = useNotifications()

  const loading = ref(false)

  onMounted(() => {
    wsClient.addListener(groupId, callbacks)
  })

  onBeforeUnmount(() => {
    wsClient.removeListener(groupId)
  })

  async function listenScriptExecution(transactionId) {
    const data = {}
    loading.value = true
    try {
      await scripts.listenScriptExecution(groupId)(transactionId)
      data.response = true
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.scripts.ws.listenScriptExecution.error'))
      console.error('script execution listening request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  async function stopListenScriptExecution() {
    const data = {}
    loading.value = true
    try {
      await scripts.stopListenScriptExecution(groupId)()
      data.response = true
    } catch(e) {
      data.error = e
      notificationsStore.showNotification('error', $t('api.scripts.ws.stopListenScriptExecution.error'))
      console.error('stop listening script execution request error', e)
    } finally {
      loading.value = false
    }

    return data
  }

  return {
    loading,
    listenScriptExecution,
    stopListenScriptExecution
  }
}
