import { onBeforeUnmount, onMounted, ref } from 'vue'

const RESIZE_SIDES = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left'
}

/**
 * @param {Object} refs
 * @param {Ref=} refs.dragTopRef - top slider ref
 * @param {Ref=} refs.dragRightRef - right slider ref
 * @param {Ref=} refs.dragBottomRef - bottom slider ref
 * @param {Ref=} refs.dragLeftRef - left slider ref
 * @param {Ref=} refs.resizableRef - resizable target ref
 * @param {Object} callbacks - resize callbacks
 * @param {function} callbacks.onResize - return new dimensions of block and trigger side
 * @param {function=} callbacks.onResizeStart - invoke on resize start
 * @param {function=} callbacks.onResizeEnd - invoke on resize end
 */
function useResizeBlock({
  dragTopRef,
  dragRightRef,
  dragBottomRef,
  dragLeftRef,
  resizableRef
}, callbacks) {
  const hasMoveTarget = ref(false)
  const resizeSide = ref()

  onMounted(() => {
    if (dragTopRef) {
      dragTopRef.value.addEventListener('mousedown', onResizeTopStart)
    }
    if (dragRightRef) {
      dragRightRef.value.addEventListener('mousedown', onResizeRightStart)
    }
    if (dragBottomRef) {
      dragBottomRef.value.addEventListener('mousedown', onResizeBottomStart)
    }
    if (dragLeftRef) {
      dragLeftRef.value.addEventListener('mousedown', onResizeLeftStart)
    }
    document.addEventListener('mousemove', onResize, { passive: true })
    document.addEventListener('mouseup', onResizeEnd)
  })

  onBeforeUnmount(() => {
    if (dragTopRef) {
      dragTopRef.value?.removeEventListener('mousedown', onResizeTopStart)
    }
    if (dragRightRef) {
      dragRightRef.value?.removeEventListener('mousedown', onResizeRightStart)
    }
    if (dragBottomRef) {
      dragBottomRef.value?.removeEventListener('mousedown', onResizeBottomStart)
    }
    if (dragLeftRef) {
      dragLeftRef.value?.removeEventListener('mousedown', onResizeLeftStart)
    }
    document.removeEventListener('mousemove', onResize, { passive: true })
    document.removeEventListener('mouseup', onResizeEnd)
  })

  function onResizeTopStart(event) {
    resizeSide.value = RESIZE_SIDES.TOP
    onResizeStart(event)
  }

  function onResizeRightStart(event) {
    resizeSide.value = RESIZE_SIDES.RIGHT
    onResizeStart(event)
  }

  function onResizeBottomStart(event) {
    resizeSide.value = RESIZE_SIDES.BOTTOM
    onResizeStart(event)
  }

  function onResizeLeftStart(event) {
    resizeSide.value = RESIZE_SIDES.LEFT
    onResizeStart(event)
  }

  function onResizeStart(event) {
    event.stopPropagation()
    event.preventDefault()
    hasMoveTarget.value = true
    callbacks.onResizeStart?.()
  }

  function onResizeEnd(event) {
    event.stopPropagation()
    event.preventDefault()
    if (hasMoveTarget.value) {
      hasMoveTarget.value = false
      callbacks.onResizeEnd?.()
    }
  }

  function onResize(event) {
    if (hasMoveTarget.value) {
      resizeBlock(event)
    }
  }

  const resizeBlock = e => {
    const block = resizableRef.value
    if (block) {
      const rect = block.getBoundingClientRect()
      callbacks?.onResize({
        trigger: resizeSide.value,
        width: resizeSide.value === RESIZE_SIDES.RIGHT
          ? e.clientX - rect.left
          : rect.left + rect.width - e.clientX,
        height: resizeSide.value === RESIZE_SIDES.BOTTOM
          ? e.clientY - rect.top
          : rect.top + rect.height - e.clientY
      })
    }
  }
}

export default useResizeBlock
