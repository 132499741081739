import http from '../instance'

const getGates = projectId => {
  return http.get('/gateways', { params: { projectId } })
}

const editGate = ({ gatewayId, name, channel, serial, description }) => {
  return http.post(`/gateways/${gatewayId}/edit`, {
    name,
    channel,
    serial,
    description,
    throughput: 123
  })
}

const addGate = ({ projectId, name, channel, serial, description }) => {
  return http.post('/gateways', {
    projectId,
    name,
    channel,
    serial,
    description,
    throughput: 123
  })
}

const deleteGate = gatewayId => {
  return http.post(`/gateways/${gatewayId}/delete`)
}

export default {
  getGates,
  editGate,
  addGate,
  deleteGate
}
