import instance from '../instance'
import { composeMsg } from '../instance/utils'

const listenTags = groupId => tagIds =>
  instance.sendMessage(groupId, composeMsg('LISTEN_TAGS', { tagIds }))

const stopListenTags = groupId => () =>
  instance.sendMessage(groupId, composeMsg('STOP_LISTEN_TAGS'))

export default {
  listenTags,
  stopListenTags
}
