import { inject } from 'vue'

import { MODAL } from '@/common/constants'

/**
 * Give access to configure the modal callbacks.
 * Available only inside AppGlobalModal
 * @param {ModalCallbacks} callbacks
 */
export default function (callbacks) {
  /**
   * @type Modal
   */
  const controller = inject(MODAL.CONTEXT_PROVIDER_KEY)
  try {
    controller.setCallbacks(callbacks)
  } catch (e) {
    console.error('out of global modal context', e)
  }
}
