import { createApp } from 'vue/dist/vue.esm-bundler'
import { createPinia } from 'pinia'

import router from '@/router'
import localization from '@/localization'

import App from './app'
import '@/validation/init'

import '@/styles/styles.scss'
import '@/styles/reset.scss'
import('@/styles/fonts.scss')

const store = createPinia()
const app = createApp(App)

app.use(store)
app.use(router)
app.use(localization)

app.mount('#app')
