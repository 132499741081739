import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { scriptNodes } from '@/api'
import { useNotifications } from '@/stores'

export default function () {
  const { t: $t } = useI18n({ useScope: 'global' })
  const $route = useRoute()
  const notificationsStore = useNotifications()

  const loading = ref(false)
  const processing = ref(false)

  async function getScriptsCatalog() {
    const data = {}
    loading.value = true
    try {
      const response = await scriptNodes.getScriptsCatalog({ projectId: $route.params.projectId })
      data.response = response.catalog
    } catch (e) {
      data.error = e
      console.error('get catalog error', e)
      notificationsStore.showNotification('error', $t('api.scriptNodes.http.getScriptsCatalog.error'))
    } finally {
      loading.value = false
    }

    return data
  }

  async function addScriptNode({ title, scriptNodeId = null }) {
    const data = {}
    processing.value = true
    try {
      const response = await scriptNodes.addScriptNode({
        title,
        projectId: $route.params.projectId,
        scriptNodeId
      })
      data.response = response.node
    } catch (e) {
      data.error = e
      console.error('add node error', e)
      notificationsStore.showNotification('error', $t('api.scriptNodes.http.addScriptNode.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function renameScriptFolder({ id, title }) {
    const data = {}
    processing.value = true
    try {
      const response = await scriptNodes.renameScriptFolder({
        id: id,
        title: title
      })
      data.response = response.node
    } catch (e) {
      data.error = e
      console.error('add node error', e)
      notificationsStore.showNotification('error', $t('api.scriptNodes.http.renameScriptFolder.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function deleteScriptFolder({ folderId, safeChildren }) {
    const data = {}
    processing.value = true
    try {
      const response = await scriptNodes.deleteScriptFolder({ folderId, safeChildren })
      data.response = response.catalog
      notificationsStore.showNotification('success', $t('api.scriptNodes.http.deleteScriptFolder.success'))
    } catch (e) {
      data.error = e
      console.error(e)
      notificationsStore.showNotification('error', $t('api.scriptNodes.http.deleteScriptFolder.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  async function moveInScriptFolder({ scriptNodeId, scriptIds, macroIds }) {
    const data = {}
    processing.value = true
    try {
      const response = await scriptNodes.moveInScriptFolder({ scriptNodeId, scriptIds, macroIds })
      data.response = response.catalog
    } catch (e) {
      data.error = e
      console.error(e)
      notificationsStore.showNotification('error', $t('api.scriptNodes.http.moveInScriptFolder.error'))
    } finally {
      processing.value = false
    }

    return data
  }

  return {
    loading,
    processing,
    getScriptsCatalog,
    addScriptNode,
    renameScriptFolder,
    deleteScriptFolder,
    moveInScriptFolder
  }
}
