const TYPES = {
  NODE: 'NODE',
  SCRIPT: 'SCRIPT',
  MACROS: 'MACROS'
}

const REPEAT_TYPES = {
  EVERY_DAY: 'EVERY_DAY',
  EVERY_WEEK: 'EVERY_WEEK',
  EVERY_MONTH: 'EVERY_MONTH',
  EVERY_YEAR: 'EVERY_YEAR',
  CHOOSEN_WEEK_DAYS: 'CHOOSEN_WEEK_DAYS',
  CUSTOM_SCHEDULE: 'CUSTOM_SCHEDULE'
}

const INTERVAL_TYPES = {
  SEC: 'SEC',
  MIN: 'MIN',
  HOUR: 'HOUR',
  DAYS: 'DAYS'
}

const TRIGGER_TYPES = {
  NONE: 'NONE',
  SCHEDULE: 'SCHEDULE',
  TAG_UPDATE: 'TAG_UPDATE',
  NOTIFICATION: 'NOTIFICATION'
}

const TRIGGER_TYPE_TRANSLATES = Object.keys(TRIGGER_TYPES).reduce((acc, type) => {
  acc[type] = `domains.script.trigger.type.${type}.title`
  return acc
}, {})

const REPEAT_CHOOSEN_DAYS = {
  MON: 'MON',
  TUE: 'TUE',
  WEN: 'WEN',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN'
}

const REPEAT_CHOOSEN_DAY_LIST = Object.keys(REPEAT_CHOOSEN_DAYS).map(day => ({
  id: day,
  title: `domains.script.trigger.type.${TRIGGER_TYPES.SCHEDULE}.repeatChoosenDayList.${day}`
}))

export default {
  TYPES,
  REPEAT_TYPES,
  INTERVAL_TYPES,
  TRIGGER_TYPES,
  TRIGGER_TYPE_TRANSLATES,
  REPEAT_CHOOSEN_DAYS,
  REPEAT_CHOOSEN_DAY_LIST
}
