import { provide } from 'vue'

import { FORM } from '@/common/constants'

/**
 * Detects changes of child components that used `usedFormField`
 * @param {function(path, value, oldValue)} onChangeCallback - invoke on form change
 * @param {Object} options
 * @param {boolean} options.inputOnBlurOnly
 */
export default function (onChangeCallback, {
  inputOnBlurOnly
}) {
  provide(FORM.OBSERVER_KEY, {
    onChange
  })

  function onChange(control, event, path, value, oldValue) {
    const isInput = control === FORM.CONTROL_TYPES.INPUT
    const isBlurEvent = isInput && event === FORM.CONTROL_EVENTS.BLUR
    if (
      !isInput
      || (!inputOnBlurOnly || isBlurEvent && value !== oldValue)
    ) {
      onChangeCallback(path, value, oldValue)
    }
  }
}
